<template>
    <v-app>
      <v-container class="technicien mx-1 mb-1 pa-5">
        <!-- Search Input -->
        <v-col cols="4" md="4">
          <v-text-field
            v-model="search"
            color="#2382bf"
            type="text"
            hide-no-data
            placeholder="Recherche Technicien"
            prepend-icon="mdi-database-search"
            dense
          ></v-text-field>
        </v-col>
  
        <!-- Data Table with Search -->
        <v-data-table
          :headers="headers"
          :items="filteredTechniciens"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:top>
            <!-- Table Toolbar -->
            <v-toolbar flat class="pa-2 grey lighten-4 toolbar-border">
              <v-toolbar-title>Liste des Techniciens</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn @click="openAddModal" color="blue darken-1" icon>
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-toolbar>
          </template>
  
          <!-- Actions Column (Edit/Delete) -->
          <template v-slot:item.actions="{ item }">
            <router-link :to="{ name: 'edituser', params: { id: item._id } }">
              <v-icon class="me-2">mdi-pencil</v-icon>
            </router-link>
            <v-icon @click="confirmDeleteTechnicien(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
  
        <!-- Add Technicien Dialog -->
        <v-dialog v-model="addModal" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Ajouter Technicien</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="newTechnicien.name"
                  label="Nom"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
                
                <v-text-field
                  v-model="newTechnicien.email"
                  label="Email"
                  :rules="[rules.required, rules.email]"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="newTechnicien.telephone"
                  label="Téléphone"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="newTechnicien.adresse"
                  label="Adresse"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeAddModal"
                >Annuler</v-btn
              >
              <v-btn color="blue darken-1" text @click="addTechnicien"
                >Ajouter</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
  
        <!-- Confirm Delete Dialog -->
        <v-dialog v-model="confirmDeleteModal" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Confirmer la suppression</span>
            </v-card-title>
            <v-card-text>
              Êtes-vous sûr de vouloir supprimer ce technicien ?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeConfirmDelete"
                >Annuler</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteTechnicien"
                >Confirmer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
  
        <!-- Edit Technicien Dialog -->
        <v-dialog v-model="editModal" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Modifier Technicien</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="editForm" v-model="validEdit" lazy-validation>
                <v-text-field
                  v-model="selectedTechnicien.name"
                  label="Nom"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
                
                <v-text-field
                  v-model="selectedTechnicien.email"
                  label="Email"
                  :rules="[rules.required, rules.email]"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="selectedTechnicien.telephone"
                  label="Téléphone"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="selectedTechnicien.adresse"
                  label="Adresse"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditModal"
                >Annuler</v-btn
              >
              <v-btn color="blue darken-1" text @click="updateTechnicien"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-app>
  </template>
  
  <script>
  import { HTTP } from "@/axios"; // Adjust the import according to your setup
  
  export default {
    data() {
      return {
        search: "",
        valid: false,
        validEdit: false,
        addModal: false,
        editModal: false,
        confirmDeleteModal: false,
        techniciens: [],
        selectedTechnicien: {},
        newTechnicien: {
          name: "",
       
          email: "",
          adresse: "",
          telephone: "",
        },
        headers: [
          { text: "Nom", value: "name" },
          { text: "Email", value: "email" },
          { text: "Téléphone", value: "telephone" },
          { text: "Actions", value: "actions", sortable: false },
        ],
        rules: {
          required: (value) => !!value || "Ce champ est requis.",
          email: (value) => {
            const emailPattern =
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailPattern.test(value) || "Email invalide.";
          },
        },
      };
    },
    created() {
      this.getTechniciens();
    },
    computed: {
      filteredTechniciens() {
        const searchTerm = this.search.toLowerCase();
        return this.techniciens.filter((technicien) =>
          ["nom", "prenom", "email", "telephone"].some(
            (key) =>
              technicien[key] &&
              technicien[key].toLowerCase().includes(searchTerm) // Ensure property exists before calling toLowerCase()
          )
        );
      },
    },
    methods: {
      openAddModal() {
        this.addModal = true;
      },
      closeAddModal() {
        this.addModal = false;
        this.resetNewTechnicien();
      },
      openEditModal(technicien) {
        this.selectedTechnicien = { ...technicien };
        this.editModal = true;
      },
      closeEditModal() {
        this.editModal = false;
        this.selectedTechnicien = {};
      },
      confirmDeleteTechnicien(technicien) {
        this.selectedTechnicien = technicien;
        this.confirmDeleteModal = true;
      },
      closeConfirmDelete() {
        this.confirmDeleteModal = false;
        this.selectedTechnicien = {};
      },
      getTechniciens() {
    this.loading = true;
    HTTP.get("users/getusers")
      .then((response) => {
        console.log("API Response:", response.data); // Log the full response data
        this.techniciens = response.data.filter(
          (user) => user.role === "technicien"
        );
        console.log("Filtered Technicians:", this.techniciens); // Log filtered technicians
        this.loading = false; // Set loading to false when data is loaded
      })
      .catch((err) => {
        console.error("Error fetching technicians:", err);
        this.loading = false; // Ensure loading is stopped even on error
      });
},
      addTechnicien() {
        if (this.$refs.form.validate()) {
          HTTP.post("users/addtechnicien", this.newTechnicien)
            .then(() => {
              this.getTechniciens();
              this.closeAddModal();
            })
            .catch((err) => console.error(err));
        }
      },
      updateTechnicien() {
        if (this.$refs.editForm.validate()) {
          HTTP.put(
            `users/${this.selectedTechnicien._id}`,
            this.selectedTechnicien
          )
            .then(() => {
              this.getTechniciens();
              this.closeEditModal();
            })
            .catch((err) => console.error(err));
        }
      },
      deleteTechnicien() {
        HTTP.delete(`users/deleteuser/${this.selectedTechnicien._id}`)
          .then(() => {
            this.getTechniciens();
            this.closeConfirmDelete();
          })
          .catch((err) => console.error(err));
      },
      resetNewTechnicien() {
        this.newTechnicien = {
          nom: "",
          prenom: "",
          email: "",
          adresse: "",
          telephone: "",
        };
      },
    },
  };
  </script>
  
  <style scoped>
  .technicien {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
  }
  
  .toolbar-border {
    border-bottom: 1px solid #e0e0e0;
  }
  
  .v-data-table {
    margin-top: 20px;
  }
  
  .v-dialog {
    padding: 15px;
  }
  </style>
  