<template>
  <v-app>
    <v-container class="support-requests mx-1 mb-1 pa-5">
      <!-- Champ de recherche et table -->
      <v-col cols="4" md="4">
        <v-text-field
          v-model="search"
          color="#2382bf"
          type="text"
          hide-no-data
          placeholder="Recherche type Produit"
          prepend-icon="mdi-database-search"
          dense
        ></v-text-field>
      </v-col>
      <v-data-table
        :headers="headers"
        :items="typesProduits"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat class="pa-2 grey lighten-4 toolbar-border">
            <v-toolbar-title>Liste des Types de Produits</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="primary mb-2" @click="openAddModal"
              >Ajouter Type de Produit</v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="me-2" @click="openEditModal(item)">mdi-pencil</v-icon>
          <v-icon @click="confirmDeleteTypeProduit(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>

      <!-- Modal pour ajouter un type de produit -->
      <v-dialog v-model="addModal" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Ajouter Type de Produit</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="newTypeProduit.nom"
                label="Nom"
                :rules="[rules.required]"
                required
              ></v-text-field>
              <v-text-field
                v-model="newTypeProduit.description"
                label="Description"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                v-model="newTypeProduit.categorie"
                label="Catégorie"
                :rules="[rules.required]"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeAddModal"
              >Annuler</v-btn
            >
            <v-btn color="blue darken-1" text @click="addTypeProduit"
              >Ajouter</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal pour confirmer la suppression -->
      <v-dialog v-model="confirmDeleteModal" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Confirmer la suppression</span>
          </v-card-title>
          <v-card-text>
            Êtes-vous sûr de vouloir supprimer ce type de produit ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeConfirmDelete"
              >Annuler</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteTypeProduit"
              >Confirmer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal pour modifier un type de produit -->
      <v-dialog v-model="editModal" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Modifier Type de Produit</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="editForm" v-model="validEdit" lazy-validation>
              <v-text-field
                v-model="selectedTypeProduit.nom"
                label="Nom"
                :rules="[rules.required]"
                required
                :disabled="!selectedTypeProduit"
              ></v-text-field>
              <v-text-field
                v-model="selectedTypeProduit.description"
                label="Description"
                :rules="[rules.required]"
                :disabled="!selectedTypeProduit"
              ></v-text-field>
              <v-text-field
                v-model="selectedTypeProduit.categorie"
                label="Catégorie"
                :rules="[rules.required]"
                required
                :disabled="!selectedTypeProduit"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditModal"
              >Annuler</v-btn
            >
            <v-btn color="blue darken-1" text @click="updateTypeProduit"
              >Enregistrer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import { HTTP } from "@/axios";

export default {
  data() {
    return {
      search: "",

      valid: false,
      validEdit: false,
      addModal: false,
      editModal: false,
      confirmDeleteModal: false,
      typesProduits: [], // Liste des types de produits
      selectedTypeProduit: {}, // Initialiser comme objet vide
      newTypeProduit: {
        nom: "",
        description: "",
        categorie: "",
      },
      headers: [
        { text: "Nom", value: "nom" },
        { text: "Description", value: "description" },
        { text: "Catégorie", value: "categorie" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Ce champ est requis.",
      },
    };
  },
  created() {
    this.getTypesProduits();
  },
  methods: {
    openAddModal() {
      this.addModal = true;
    },
    closeAddModal() {
      this.addModal = false;
    },
    openEditModal(typeProduit) {
      this.selectedTypeProduit = { ...typeProduit }; // Crée une copie pour éviter les modifications non souhaitées
      this.editModal = true;
    },
    closeEditModal() {
      this.editModal = false;
      this.selectedTypeProduit = {}; // Réinitialiser à un objet vide
    },
    confirmDeleteTypeProduit(typeProduit) {
      this.selectedTypeProduit = typeProduit;
      this.confirmDeleteModal = true;
    },
    closeConfirmDelete() {
      this.confirmDeleteModal = false;
      this.selectedTypeProduit = {}; // Réinitialiser à un objet vide
    },
    getTypesProduits() {
      HTTP.get("typeproduit/tous")
        .then((response) => {
          this.typesProduits = response.data;
        })
        .catch((err) => console.log(err));
    },
    async addTypeProduit() {
      if (this.$refs.form.validate()) {
        try {
          const response = await HTTP.post(
            "typeproduit/ajouter",
            this.newTypeProduit
          );
          this.$toast.success("Type de produit ajouté avec succès !", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });
          this.getTypesProduits();
          this.closeAddModal();
        } catch (error) {
          this.$toast.error(
            "Une erreur est survenue lors de l'ajout du type de produit.",
            {
              position: "top-right",
              timeout: 2000,
              closeOnClick: true,
            }
          );
          console.error("Erreur lors de l'ajout du type de produit:", error);
        }
      }
    },
    async updateTypeProduit() {
      if (this.$refs.editForm.validate() && this.selectedTypeProduit) {
        try {
          await HTTP.put(
            `typeproduit/mise-a-jour/${this.selectedTypeProduit._id}`,
            this.selectedTypeProduit
          );
          this.$toast.success("Type de produit modifié avec succès !", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });
          this.getTypesProduits();
          this.closeEditModal();
        } catch (error) {
          this.$toast.error(
            "Une erreur est survenue lors de la modification du type de produit.",
            {
              position: "top-right",
              timeout: 2000,
              closeOnClick: true,
            }
          );
          console.error(
            "Erreur lors de la modification du type de produit:",
            error
          );
        }
      }
    },
    async deleteTypeProduit() {
      if (this.selectedTypeProduit) {
        try {
          await HTTP.delete(
            `typeproduit/supprimer/${this.selectedTypeProduit._id}`
          );
          this.$toast.success("Type de produit supprimé avec succès !", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });
          this.getTypesProduits();
          this.closeConfirmDelete();
        } catch (error) {
          this.$toast.error(
            "Une erreur est survenue lors de la suppression du type de produit.",
            {
              position: "top-right",
              timeout: 2000,
              closeOnClick: true,
            }
          );
          console.error(
            "Erreur lors de la suppression du type de produit:",
            error
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.custom-toolbar {
  background-color: #9e9e9e;
}
</style>
