<template>
  <div class="container-login">
    <div class="container-image"></div>
    <div class="container-form">
      <div class="logo">
        <img src="../../assets/Trustlink.png" />
      </div>
      <span class="identifier">Réinitialisation</span>
      <v-form class="form-reset" @submit.prevent="sendResetRequest">
        <v-col>
          <v-text-field
            v-model="email"
            :error-messages="emailErrors"
            label="E-mail"
            required
            @blur="emailTouched = true"
            @input="emailTouched = true"
            class="input-label"
          ></v-text-field>
        </v-col>

        <div class="form-footer">
          <v-btn type="submit" :disabled="!email.length"> Envoyer </v-btn>
          <router-link to="/login">
            <v-btn> Retour </v-btn>
          </router-link>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { HTTP } from "@/axios";

const email = ref("");
const emailTouched = ref(false);

const emailErrors = computed(() => {
  const errors = [];
  if (!email.value && emailTouched.value) errors.push("E-mail est obligatoire");
  if (email.value && !/.+@.+/.test(email.value))
    errors.push("E-mail doit être valide");
  return errors;
});

const sendResetRequest = async () => {
  try {
    const response = await HTTP.get(`auth/getemail/${email.value}`);
    if (response.data === "adresse mail existe") {
      alert("Veuillez vérifier votre boîte mail !");
      // Redirection après succès
      // this.$router.push("/login");
    } else {
      alert("Adresse n'existe pas, veuillez vérifier !");
    }
  } catch (err) {
    console.error(err);
    alert("Une erreur est survenue");
  }
};
</script>

<style scoped>
.container-login {
  display: flex;
  height: 100vh;
  overflow: hidden;
  width: 100% !important;
}

.container-image {
  flex: 2.3;
  background: url("https://www.ltvplus.com/wp-content/uploads/2019/06/Good-Customer-Service-featured.png")
    no-repeat center center;
  background-size: cover;
}

.container-form {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f7ff;
  flex-direction: column;
  gap: 2.5rem;
  min-width: 350px;
}

.identifier {
  color: #536dfe !important;
  font-size: 1.8rem;
  border-bottom: 2px solid #536dfe;
  width: 60%;
  display: inline-block;
  text-align: center;
}

.form-reset {
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 2rem;
}

.form-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
}
</style>
