<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">Ajouter Client</h1>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <router-link to="/clients">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                class="text-capitalize button-shadow mr-1"
                >Retour</v-btn
              >
            </router-link>
          </template>
        </v-menu>
      </v-row>
      <v-row>
        <div style="width: 1000px; text-align: center">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="18" md="4">
                <v-radio-group v-model="typeUser" row>
                  <v-radio
                    color="primary"
                    label="Particulier"
                    value="particulier"
                  ></v-radio>
                  <v-radio
                    color="primary"
                    label="Société"
                    value="societe"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <div class="bordered-row" v-if="typeUser === 'particulier'">
              <v-row>
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Nom"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="prenom"
                    :rules="prenomRules"
                    label="Prénom"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="telephone"
                    :counter="8"
                    :rules="telephoneRules"
                    label="Téléphone"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="adresse"
                    :rules="adresseRules"
                    label="Adresse"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <div class="bordered-row" v-if="typeUser === 'societe'">
              <v-row>
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="societe.raisonSociale"
                    :rules="societeRules"
                    label="Raison Sociale"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="societe.RNE"
                    :rules="societeRules"
                    label="RNE"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="societe.web"
                    :rules="societeRules"
                    label="Web"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="matricule_fiscale"
                    label="Matricule fiscale"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="societe.fax"
                    :rules="societeRules"
                    label="Fax"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="societe.MF"
                    :rules="societeRules"
                    label="MF"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="adresse"
                    :rules="adresseRules"
                    label="Adresse"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <div class="bordered-row" v-if="typeUser === 'societe'">
              <div class="ticket-type-label">
                <span style="display: flex; text-align: start"
                  >Contact société</span
                >
              </div>
              <v-row>
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Nom"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="prenom"
                    :rules="prenomRules"
                    label="Prénom"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="post"
                    :rules="prenomRules"
                    label="Post"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field
                    v-model="telephone"
                    :counter="8"
                    :rules="telephoneRules"
                    label="Téléphone"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <v-btn
              style="margin-right: 5px"
              :disabled="!valid"
              color="success"
              @click="validate"
            >
              Ajouter
            </v-btn>
          </v-form>
        </div>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { HTTP } from "@/axios";
import Swal from "sweetalert2";

export default {
  data: () => ({
    roles: ["admin", "client", "technicien", "réception"],
    role: "client",
    typeUser: "particulier",
    valid: true,
    name: "",
    post: "",
    prenom: "",
    nameRules: [(v) => !!v || "Nom est obligatoire"],
    prenomRules: [(v) => !!v || "Prénom est obligatoire"],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail est obligatoire",
      (v) => /.+@.+/.test(v) || "E-mail doit être valide",
    ],
    telephone: "",
    telephoneRules: [(v) => !!v || "Téléphone est obligatoire"],
    adresse: "",
    adresseRules: [(v) => !!v || "Adresse est obligatoire"],
    show1: false,
    password: "",
    societe: {
      raisonSociale: "",
      RNE: "",
      MF: "",
      web: "",
      fax: "",
      matricule_fiscale: "",
    },
    societeRules: [(v) => !!v || "Ce champ est obligatoire"],
    rules: {
      required: (value) => !!value || "Champ obligatoire.",
      min: (v) => v.length >= 8 || "Min 8 caractères",
    },
  }),

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        const client = {
          name: this.name,
          prenom: this.prenom,
          email: this.email,
          telephone: this.telephone,
          adresse: this.adresse,
          typeUser: this.typeUser,
          post: this.post,
          societe: {
            raisonSociale: this.societe.raisonSociale,
            RNE: this.societe.RNE,
            MF: this.societe.MF,
            web: this.societe.web,
            fax: this.societe.fax,
            matricule_fiscale: this.matricule_fiscale,
          },
        };

        HTTP.post("clients/addclient", client)
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Client ajouté",
              text: "Client a été ajouté avec succès!",
            });
            this.$router.push("/clients");
          })
          .catch((error) => {
            console.error(error);
            Swal.fire({
              icon: "error",
              title: "Erreur",
              text: "Une erreur est survenue lors de l'ajout de Client.",
            });
          });
      }
    },
  },
};
</script>

<style scoped>
.dashboard-page {
  max-width: 1200px;
  margin: auto;
  background-color: white;
  padding: 20px;
}

.page-title {
  font-weight: bold;
}
.bordered-row {
  border: 2px solid #ccc;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 15px;
  position: relative; /* Ajouté pour permettre au label d'être positionné absolu par rapport à ce conteneur */
}

.ticket-type-label {
  position: absolute;
  top: 0px;
  left: 0px; /* Modifié de right à left pour placer le label à gauche */
  background: blue;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 13px;
  font-weight: bold;
  z-index: 10;
  -webkit-clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
  clip-path: polygon(0 0, 100% 0, 73% 100%, 0% 100%);
  text-align: center;
  width: 150px;
}
</style>
