<template>
  <v-app>
    <v-container class="support-requests mx-1 mb-1 pa-5">
      <!-- Champ de recherche et table -->
      <v-col cols="4" md="4">
        <v-text-field
          v-model="search"
          color="#2382bf"
          type="text"
          hide-no-data
          placeholder="Recherche utilisateur"
          prepend-icon="mdi-database-search"
          dense
        ></v-text-field>
      </v-col>

      <v-data-table
        :headers="headers"
        :items="utilisateurs"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat class="pa-2 grey lighten-4 toolbar-border">
            <v-toolbar-title>Liste des Utilisateurs</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="primary mb-2" @click="openAddModal">Ajouter Utilisateur</v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{ item }">
          <router-link
            :to="{ name: 'edituser', params: { id: item._id } }"
          >
            <v-icon class="me-2">mdi-pencil</v-icon>
          </router-link>
          <v-icon @click="confirmDeleteUser(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>

      <!-- Modal pour ajouter un utilisateur -->
      <v-dialog v-model="addModal" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Ajouter Utilisateur</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field v-model="newUtilisateur.name" label="Nom" :rules="[rules.required]" required></v-text-field>
              <v-text-field v-model="newUtilisateur.email" label="Email" :rules="[rules.required, rules.email]" required></v-text-field>
              <v-text-field v-model="newUtilisateur.telephone" label="Téléphone" :rules="[rules.required]" required></v-text-field>
              <v-text-field v-model="newUtilisateur.adresse" label="Adresse" :rules="[rules.required]" required></v-text-field>
              <v-text-field v-model="newUtilisateur.role" label="Role" :rules="[rules.required]" required></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeAddModal">Annuler</v-btn>
            <v-btn color="blue darken-1" text @click="addUtilisateur">Ajouter</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal pour confirmer la suppression -->
      <v-dialog v-model="confirmDeleteModal" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Confirmer la suppression</span>
          </v-card-title>
          <v-card-text>
            Êtes-vous sûr de vouloir supprimer cet utilisateur ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeConfirmDelete">Annuler</v-btn>
            <v-btn color="blue darken-1" text @click="deleteUser">Confirmer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal pour modifier un utilisateur -->
      
    </v-container>
  </v-app>
</template>

<script>
import { HTTP } from "@/axios"; // Ensure the correct path to your axios instance

export default {
  data() {
    return {
      search: "",
      valid: false,
      validEdit: false,
      addModal: false,
      editModal: false,
      confirmDeleteModal: false,
      utilisateurs: [], // Liste des utilisateurs
      selectedUtilisateur: {}, // Initialiser comme objet vide
      newUtilisateur: {
        name: "",
        email: "",
        telephone: "",
        adresse: "",
        role: "",
      },
      headers: [
        { text: "Nom", value: "name" },
        { text: "Email", value: "email" },
        { text: "Téléphone", value: "telephone" },
        { text: "Adresse", value: "adresse" },
        { text: "Role", value: "role" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Ce champ est requis.",
        email: (value) => {
          const pattern = /.+@.+\..+/;
          return pattern.test(value) || "E-mail invalide.";
        },
      },
    };
  },

  created() {
    this.getUtilisateurs();
  },

  methods: {
    openAddModal() {
      this.addModal = true;
    },
    closeAddModal() {
      this.addModal = false;
      this.newUtilisateur = { name: "", email: "", telephone: "", adresse: "", role: "" }; // Reset form
    },
    openEditModal(utilisateur) {
      this.selectedUtilisateur = { ...utilisateur }; // Clone the user to edit
      this.editModal = true;
    },
    closeEditModal() {
      this.editModal = false;
      this.selectedUtilisateur = {}; // Reset to empty object
    },
    confirmDeleteUser(utilisateur) {
      this.selectedUtilisateur = utilisateur; // Set the user to delete
      this.confirmDeleteModal = true;
    },
    closeConfirmDelete() {
      this.confirmDeleteModal = false;
      this.selectedUtilisateur = {}; // Reset to empty object
    },
    getUtilisateurs() {
      HTTP.get("users/getusers")
        .then((response) => {
          this.utilisateurs = response.data; // Assume the response is a list of utilisateurs
        })
        .catch((err) => console.error(err));
    },
    addUser() {
      if (this.$refs.form.validate()) {
        HTTP.post("users/adduser", this.newUser)
          .then(() => {
            this.getUsers();
            this.closeAddModal();
          })
          .catch((err) => console.error(err));
      }
    },
    updateUser() {
      if (this.$refs.editForm.validate()) {
        // Use this.selectedUser._id for the URL
        HTTP.put(`users/edituser/${this.selectedUser._id}`, this.selectedUser)
          .then(() => {
            this.getUsers(); // Refresh user list after updating
            this.closeEditModal(); // Close the modal
          })
          .catch((err) => console.error(err));
      }
    },

    deleteUser() {
      // Using this.selectedUtilisateur._id to send the request
      HTTP.delete(`users/deleteuser/${this.selectedUtilisateur._id}`)
        .then(() => {
          this.getUtilisateurs(); // Refresh the user list after deletion
          this.closeConfirmDelete(); // Close the confirmation modal
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>


<style scoped>
.dashboard-page {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
}

.toolbar-border {
  border-bottom: 1px solid #e0e0e0;
}

.v-data-table {
  margin-top: 20px;
}

.v-dialog {
  padding: 15px;
}
.custom-toolbar {
  background-color: #9e9e9e;
}
.pagination-controls {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
</style>
