<template>
  <v-container fluid>
    <div class="container">
      <v-row no-gutters class="d-flex justify-space-between mt-30 mb-6">
        <h1 class="page-title">Fiche de Réception</h1>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <router-link to="/tickets">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                class="text-capitalize button-shadow mr-1"
                >Retour</v-btn
              >
            </router-link>
          </template>
        </v-menu>
      </v-row>

      <div class="row gutters" id="invoiceholder">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="card">
            <div class="card-body p-0">
              <div class="invoice-container">
                <div class="invoice-header">
                  <div class="row gutters">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <div class="custom-actions-btns mb-5">
                        <h2>Fiche de réception</h2>
                      </div>
                    </div>
                  </div>

                  <div class="row gutters">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                      <div class="col-md-6 col-12">
                        <img
                          src="../../assets/gei_logo.jpg"
                          alt="Logo"
                          style="width: 150px"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                      <address class="text-right">
                        <h6>
                          Tunisie, 54 rue du mercure Z.I ben arous 201 Ben Arous
                          <br />
                          e-mail: email@example.com,<br />
                          phone: +216 70 029 400,<br />
                          fax: +216 70 029 400,<br />
                        </h6>
                      </address>
                    </div>
                  </div>

                  <div class="row gutters">
                    <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                      <div class="invoice-details">
                        <v-toolbar-title class="tollbartitle"
                          >Details client</v-toolbar-title
                        >
                        <br />
                        <address>
                          <h6>
                            <span>Nom : {{ ticket["client_id"].name }} </span
                            ><br />
                            <span
                              >prenom : {{ ticket["client_id"].prenom }}</span
                            ><br />
                            <span
                              >adresse : {{ ticket["client_id"].adresse }}</span
                            ><br />
                            <span
                              >telephone :
                              {{ ticket["client_id"].telephone }} </span
                            ><br />
                            <span>email : {{ ticket["client_id"].email }}</span>
                          </h6>
                        </address>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                      <div class="invoice-details">
                        <div class="invoice-num">
                          <div>
                            <h6>
                              Date Réception :
                              {{ ticket.datereception | formatDate }}
                            </h6>
                            <h6>
                              Garantie :
                              <v-checkbox
                                label="oui"
                                color="oui"
                                value="oui"
                                hide-details
                              ></v-checkbox>
                              <v-checkbox
                                label="non"
                                color="non"
                                value="non"
                                hide-details
                              ></v-checkbox>
                            </h6>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <table class="table-main">
                  <thead>
                    <tr class="tabletitle">
                      <th style="text-align: center">Numéro Ticket</th>
                      <th style="text-align: center">Nom client</th>
                      <th style="text-align: center">Type produit</th>
                      <th style="text-align: center">Marque</th>
                      <th style="text-align: center">Modéle</th>
                      <th style="text-align: center">Livreur</th>
                      <th style="text-align: center">Représentant</th>
                      <th style="text-align: center">Code à barre</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="list-item"
                      v-for="(element, key) in listetickets"
                      :key="key"
                    >
                      <td style="text-align: center">{{ element.numero }}</td>
                      <td style="text-align: center">
                        {{ ticket["client_id"].name }}
                      </td>
                      <td style="text-align: center">
                        {{ getTypeProduitName(element.typeproduit) }}
                      </td>
                      <td style="text-align: center">
                        {{ element.marqueappareil }}
                      </td>
                      <td style="text-align: center">
                        {{ element.modeleappareil }}
                      </td>
                      <td style="text-align: center">
                        {{ getLivreurName(element.livreur) }}
                      </td>
                      <td style="text-align: center">
                        {{ getRepresentantName(element.representant) }}
                      </td>
                      <td style="text-align: center">
                        <barcode
                          :value="element.numero"
                          :height="40"
                          :width="2"
                        >
                          Show this if the rendering fails.
                        </barcode>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <v-col cols="12" sm="12" md="12">
                  Signature:
                  <v-textarea disabled></v-textarea>
                </v-col>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-btn
        @click="printContent('invoiceholder')"
        class="ma-2"
        color="primary"
      >
        <v-icon left dark center> mdi-cloud-upload </v-icon>Imprimer
      </v-btn>
      <v-btn @click="downloadPDF" class="ma-2" color="primary">
        <v-icon left dark center>mdi-cloud-download</v-icon>Télécharger
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { HTTP } from "@/axios";
import VueBarcode from "vue-barcode";
import JsBarcode from "jsbarcode";
import logoPath from "@/assets/gei_logo.jpg";
import $ from "jquery";
import html2pdf from "html2pdf.js";

export default {
  data() {
    return {
      ticket: {},
      listetickets: {},
      livreurs: {},
      pannes: [],
      representants: {},
  typeproduits: {},
    };
  },
  components: {
    barcode: VueBarcode,
  },
  created() {
    this.getticketById();
  },
  
  methods: {
    printContent(el) {
      var restorepage = $("body").html();
      var printcontent = $("#" + el).clone();
      var enteredtext = $("#text").val();
      $("body").empty().html(printcontent);
      window.print();
      $("body").html(restorepage);
    },
    async downloadPDF() {
      const element = document.getElementById("invoiceholder");

      // Use html2pdf to generate and download the PDF
      const options = {
        margin: [10, 10, 10, 10], // Margins for the PDF
        filename: `Fiche-Reception-${this.ticket.numero || "document"}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 }, // High quality
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      try {
        // Trigger the PDF download
        await html2pdf().from(element).set(options).save();
        console.log("PDF successfully downloaded.");
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
    async downloadPDFAndSendEmail() {
      const element = document.getElementById("invoiceholder"); // Get the HTML content
      let htmlContent = element.innerHTML; // Capture the HTML

      // Generate barcode images
      this.listetickets.forEach((element) => {
        const canvas = document.createElement("canvas");
        JsBarcode(canvas, element.numero, {
          format: "CODE128",
          displayValue: false,
          width: 2,
          height: 40,
        });
        element.barcodeImg = canvas.toDataURL("image/png"); // Store the image as a Data URL
      });
      const formatDate = (isoString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(isoString).toLocaleDateString("fr-FR", options); // French locale
      };
      const logoBase64 = await this.getBase64ImageFromURL(logoPath);
      // Format the reception date
      const formattedReceptionDate = formatDate(this.ticket.datereception);
      // Prepare the HTML content with embedded barcodes
      htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Fiche de Réception</title>
    </head>
    <body style="margin: 0; padding: 0; background: #f5f6fa; font-family: Arial, sans-serif;">
        <div style="margin: 20px; color: #2e323c;">
            <h1 style="text-align: center;">Fiche de réception</h1>
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <img src="${logoBase64}" alt="Logo" style="width: 150px;" />
                <address style="text-align: right;">
                    <h6 style="margin: 0; font-size: 0.8rem; color: #9fa8b9;">
                        Tunisie, 54 rue du mercure Z.I ben arous 201 Ben Arous<br />
                        e-mail: email@example.com,<br />
                        phone: +216 70 029 400,<br />
                        fax: +216 70 029 400
                    </h6>
                </address>
            </div>

            <h2 style="margin-top: 20px;">Détails client</h2>
            <address style="font-size: 0.8rem; color: #2e323c;">
                <h6>
                    <span>Nom : ${this.ticket["client_id"].name}</span><br />
                    <span>Prenom : ${
                      this.ticket["client_id"].prenom
                    }</span><br />
                    <span>Adresse : ${
                      this.ticket["client_id"].adresse
                    }</span><br />
                    <span>Téléphone : ${
                      this.ticket["client_id"].telephone
                    }</span><br />
                    <span>Email : ${this.ticket["client_id"].email}</span>
                </h6>
            </address>

            <h2 style="margin-top: 20px;">Informations de Réception</h2>
             <h6>Date Réception : ${formattedReceptionDate}</h6>
            <h6>Garantie:</h6>
            <div style="display: flex; align-items: center; margin-top: 5px;">
                <label style="margin-right: 10px;">
                    <input type="checkbox" name="garantie" value="oui" ${
                      this.ticket.garantie ? "checked" : ""
                    } />
                    Oui
                </label>
                <label>
                    <input type="checkbox" name="garantie" value="non" ${
                      !this.ticket.garantie ? "checked" : ""
                    } />
                    Non
                </label>
            </div>

            <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
                <thead style="background: #007ae1; color: #ffffff;">
                    <tr>
                        <th style="border: 1px solid #e0e3ec; padding: 8px;">Numéro</th>
                        <th style="border: 1px solid #e0e3ec; padding: 8px;">Client</th>
                        <th style="border: 1px solid #e0e3ec; padding: 8px;">Produit</th>
                        <th style="border: 1px solid #e0e3ec; padding: 8px;">Marque</th>
                        <th style="border: 1px solid #e0e3ec; padding: 8px;">Modèle</th>
                        <th style="border: 1px solid #e0e3ec; padding: 8px;">Livreur</th>
                        <th style="border: 1px solid #e0e3ec; padding: 8px;">Représentant</th>
                        <th style="border: 1px solid #e0e3ec; padding: 8px;">Code à barre</th>
                    </tr>
                </thead>
                <tbody>
                    ${this.listetickets
                      .map(
                        (element) => `
                    <tr>
                        <td style="border: 1px solid #e6e9f0; text-align: center; padding: 12px;">${
                          element.numero
                        }</td>
                        <td style="border: 1px solid #e6e9f0; text-align: center; padding: 12px;">${
                          this.ticket["client_id"].name
                        }</td>
                        <td style="border: 1px solid #e6e9f0; text-align: center; padding: 12px;">${this.getTypeProduitName(
                          element.typeproduit
                        )}</td>
                        <td style="border: 1px solid #e6e9f0; text-align: center; padding: 12px;">${
                          element.marqueappareil
                        }</td>
                        <td style="border: 1px solid #e6e9f0; text-align: center; padding: 12px;">${
                          element.modeleappareil
                        }</td>
                        <td style="border: 1px solid #e6e9f0; text-align: center; padding: 12px;">${this.getLivreurName(
                          element.livreur
                        )}</td>
                        <td style="border: 1px solid #e6e9f0; text-align: center; padding: 12px;">${this.getRepresentantName(
                          element.representant
                        )}</td>
                        <td style="border: 1px solid #e6e9f0; text-align: center; padding: 12px; height: 60px;">
                            <div style="display: flex; flex-direction: column; align-items: center;">
                                <img src="${
                                  element.barcodeImg
                                }" alt="Barcode" style="height: 40px;" />
                                <span style="margin-top: 5px;">${
                                  element.numero
                                }</span> <!-- Displaying the ticket number -->
                            </div>
                        </td>
                    </tr>`
                      )
                      .join("")}
                </tbody>
            </table>

            <div style="margin-top: 20px;">
                Signature:
                <textarea disabled style="width: 100%; height: 100px;"></textarea>
            </div>
        </div>
    </body>
    </html>`;

      console.log("HTML content to be sent:", htmlContent); // Log the captured HTML content

      try {
        // Create form data to send the HTML to the backend
        const formData = new FormData();
        formData.append("html", htmlContent); // Send HTML to backend
        formData.append("email", this.ticket["client_id"].email); // Send client email

        // Send the HTML and email to the backend
        const response = await HTTP.post("tickets/sendhtmltoemail", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Response from backend:", response.data); // Log the response from the backend
        console.log("HTML sent to backend for conversion and emailing.");
      } catch (error) {
        console.error("Error sending HTML to backend:", error);
      }
    },
    async getBase64ImageFromURL(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL("image/jpeg");
          resolve(dataURL);
        };
        img.onerror = (error) => {
          console.error("Error loading image:", error);
          reject(error);
        };
      });
    },
    async getticketById() {
      try {
        const res = await HTTP.get(
          "tickets/getticketbyid/" + this.$route.params.id
        );
        this.ticket = res.data;
        this.listetickets = this.ticket["tickets"];
        await this.getLivreurs(); // Fetch livreurs when getting the ticket
        await this.getRepresentants();
        await this.getTypeProduits();
        this.downloadPDFAndSendEmail();
      } catch (err) {
        console.log(err);
      }
    },

    async getLivreurs() {
      try {
        const res = await HTTP.get("livreurs/tous");
        console.log("Fetched livreurs:", res.data);
        this.livreurs = res.data.reduce((acc, livreur) => {
          acc[livreur._id] = livreur.nom; // Ensure we store the name
          return acc;
        }, {});
      } catch (error) {
        console.error("Error fetching livreurs:", error);
      }
    },

    getLivreurName(livreurId) {
      console.log("Livreur ID:", livreurId); // Log the livreur ID
      return this.livreurs[livreurId] || "Inconnu"; // Return name or 'Inconnu'
    },
    async getRepresentants() {
      try {
        const res = await HTTP.get("representants/representants");
        console.log("Fetched livreurs:", res.data);
        this.representants = res.data.reduce((acc, representant) => {
          acc[representant._id] = representant.nom; // Ensure we store the name
          return acc;
        }, {});
      } catch (error) {
        console.error("Error fetching livreurs:", error);
      }
    },
    getRepresentantName(representantId) {
      console.log("Representant ID:", representantId); // Log the livreur ID
      return this.representants[representantId] || "Inconnu"; // Return name or 'Inconnu'
    },
    async getTypeProduits() {
      try {
        const res = await HTTP.get("typeproduit/tous");
        console.log("Fetched type produits:", res.data);
        this.typeproduits = res.data.reduce((acc, typeproduit) => {
          acc[typeproduit._id] = typeproduit.nom; 
          return acc;
        }, {});
      } catch (error) {
        console.error("Error fetching Type produit:", error);
      }
    },
    getTypeProduitName(typeProduitId) {
      console.log("typeproduit ID:", typeProduitId); // Log the livreur ID
      return this.typeproduits[typeProduitId] || "Inconnu"; // Return name or 'Inconnu'
    },
  },
  async mounted() {
    this.downloadPDFAndSendEmail();
  }
};
</script>

<style src="./fiche.scss" lang="scss" />
