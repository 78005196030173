<template>
  <div class="container-Profil">
    <div class="left-Profil">
      <div class="avatar-container">
        <img src="../../assets/avatar-profil.png" alt="Avatar" class="avatar" />
        <div class="nom-prenom">
          {{ userDetail.name }} {{ userDetail.prenom }}
        </div>
        <div class="class-role">{{ role }}</div>
      </div>
      <div class="detail-user">
        <div class="detail-Profil">
          <svg-icon type="mdi" :path="mdiPhone"></svg-icon> Téléphone :
          {{ userDetail.telephone }}
        </div>
        <div class="detail-Profil">
          <svg-icon type="mdi" :path="mdiMapMarkerOutline"></svg-icon> Adresse :
          {{ userDetail.adresse }}
        </div>
        <div class="detail-Profil">
          <svg-icon type="mdi" :path="mdiEmailMultipleOutline"></svg-icon>Email
          : {{ userDetail.email }}
        </div>
      </div>
    </div>
    <div class="right-Profil">
      <div class="user-edit-container">
        <h2>Modifier le Profil</h2>
        <form @submit.prevent="updateUser" ref="form">
          <div class="input-group-profil">
            <div class="form-group">
              <label for="name">Nom:</label>
              <input type="text" v-model="name" id="name" required />
            </div>

            <div class="form-group">
              <label for="prenom">Prénom:</label>
              <input type="text" v-model="prenom" id="prenom" required />
            </div>
          </div>

          <div class="input-group-profil">
            <div class="form-group">
              <label for="email">Email:</label>
              <input type="email" v-model="email" id="email" required />
            </div>

            <div class="form-group">
              <label for="telephone">Téléphone:</label>
              <input type="tel" v-model="telephone" id="telephone" required />
            </div>
          </div>

          <div class="input-group-profil">
            <div class="form-group">
              <label for="adresse">Adresse:</label>
              <input type="text" v-model="adresse" id="adresse" required />
            </div>

            <div class="form-group">
              <label for="role">Rôle:</label>
              <select v-model="role" id="role" required>
                <option value="admin">Administrateur</option>
                <option value="client">client</option>
                <option value="technicien">technicien</option>
                <option value="réception">réception</option>
              </select>
            </div>
          </div>

          <div class="input-group-profil">
            <div class="form-group">
              <label for="password">Mot de passe:</label>
              <div
                class="password-wrapper"
                :style="{ width: showPasswordInput ? '100%' : '50%' }"
              >
                <input
                  :type="showPassword ? 'text' : 'password'"
                  v-model="passwordtext"
                  id="password"
                  required
                  readonly
                />
                <button
                  type="button"
                  @click="togglePasswordVisibility"
                  class="password-toggle"
                >
                  <span v-if="showPassword">👁️</span>
                  <span v-else>👁️‍🗨️</span>
                </button>
              </div>
            </div>
            <div v-if="showPasswordInput" class="form-group">
              <label for="password">Nouveau mot de passe :</label>
              <input
                type="text"
                v-model="nouveauPassword"
                id="password"
                ref="passwordInput"
              />
            </div>
          </div>
          <div class="button-container">
            <button type="submit" class="submit-button">
              Modifier le Profil
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-password" v-if="showModalPassworld">
      <div class="modal-content">
        <!-- Bouton de fermeture en haut à droite -->
        <button class="modal-close" @click="closeModal">X</button>

        <h2 class="modal-title">Voulez-vous changer votre mot de passe ?</h2>
        <div class="modal-buttons">
          <button @click="changePassword">Oui</button>
          <button @click="closeModal">Non</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/axios";

import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiAccountCircleOutline,
  mdiPhone,
  mdiMapMarkerOutline,
  mdiEmailMultipleOutline,
  mdiAccountCogOutline,
} from "@mdi/js";
export default {
  components: {
    SvgIcon,
  },
  data() {
    return {
      showPassword: false,
      userData: "",
      nouveauPassword: "",
      showPasswordInput: false,

      name: "",
      prenom: "",
      email: "",
      adresse: "",
      telephone: "",
      role: "",
      passwordtext: "",
      mdiAccountCircleOutline,
      mdiPhone,
      mdiMapMarkerOutline,
      mdiEmailMultipleOutline,
      mdiAccountCogOutline,
      userDetail: "",
      showModalPassworld: false,
      firstConnection: "",
    };
  },
  methods: {
    changePassword() {
      this.showPasswordInput = true;
      this.showModalPassworld = false;

      this.$nextTick(() => {
        this.$refs.passwordInput.focus();
      });
    },
    toggeModale() {
      // Utilisez this.firstConnection directement
      if (this.firstConnection) {
        console.log("First connection:", this.firstConnection); // Affiche la valeur
        this.showModalPassworld = true;
      } else {
        this.showModalPassworld = false;
      }
    },
    closeModal() {
      this.showModalPassworld = false;
      this.showPasswordInput = false;
      this.updateUser();
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async loadUserData() {
      const userDatalocalStorage = localStorage.getItem("userData");
      if (userDatalocalStorage) {
        const user = JSON.parse(userDatalocalStorage)[0];
        this.userData = user;
      }
    },
    async updateUser() {
      let user = {
        id: this.userData._id,
        email: this.email,
        name: this.name,
        prenom: this.prenom,
        telephone: this.telephone,
        role: this.role,
        adresse: this.adresse,
        password: this.passwordtext,
        firstConnection: false,
      };

      if (this.nouveauPassword !== "") {
        user.passwordtext = this.nouveauPassword;
      } else {
        user.passwordtext = this.passwordtext;
      }

      try {
        const response = await HTTP.put("users/edituser", user);

        if (response.status === 200) {
          this.getUserById();
          this.$toast.success("Profil modifié avec succès!", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });
        }
      } catch (error) {
        // Gérer les erreurs
        console.error("Erreur:", error);
        alert(
          `Erreur lors de la modification du profil : ${
            error.response ? error.response.data.message : "Erreur réseau."
          }`
        );
      }
    },

    async getUserById() {
      try {
        const response = await HTTP.get(
          `users/user-by-id/${this.userData._id}`
        );

        if (response.status !== 200) {
          throw new Error("Erreur lors de la récupération de l’utilisateur");
        }

        this.userDetail = response.data; // Récupérer les détails de l'utilisateur

        // Assigner les valeurs aux propriétés de votre composant
        this.email = this.userDetail.email;
        this.name = this.userDetail.name;
        this.prenom = this.userDetail.prenom;
        this.role = this.userDetail.role;
        this.telephone = this.userDetail.telephone;
        this.adresse = this.userDetail.adresse;
        this.passwordtext = this.userDetail.passwordtext;
        this.firstConnection = this.userDetail.firstConnection;
        this.toggeModale();
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de l'utilisateur:",
          error
        );
        alert("Erreur lors de la récupération de l'utilisateur.");
      }
    },
  },
  mounted() {
    this.loadUserData();
    this.getUserById();
  },
};
</script>

<style scoped>
.input-group-profil {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.class-role {
  color: #757575;
}
.nom-prenom {
  font-weight: 600;
  color: blue;
}
.avatar-container {
  flex-direction: column;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  height: 250px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.detail-Profil {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.35rem;
}
.detail-user {
  background: #757575;
  padding: 15px;
  color: white;
  width: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  gap: 0.8rem;
  display: flex;
  flex-direction: column;
}
.container-Profil {
  display: flex;
  width: 100%;
  height: 100vh; /* S'assure que la hauteur prend 100% de la hauteur de la fenêtre */
  gap: 2rem;
}

.left-Profil {
  flex-basis: 35%; /* Prend 35% de la largeur du conteneur */
}

.right-Profil {
  flex-basis: 65%; /* Prend 65% de la largeur du conteneur */
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
.user-edit-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2rem;
}

h2 {
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #1a1919;
  border-radius: 5px;
}

input:focus,
select:focus {
  border-color: #007bff;
  outline: none;
}

.submit-button {
  width: 50%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #0056b3;
}
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.password-wrapper {
  position: relative;
}

.password-wrapper input {
  width: 100%;
  padding-right: 40px;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}
.modal-password {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Background semi-transparent */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* S'assurer que le modal est au-dessus du contenu */
}

.modal-content {
  position: relative; /* Nécessaire pour que le bouton close soit positionné à l'intérieur */

  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 400px;
}

.modal-title {
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 35px;
  line-height: 1;
}

.modal-buttons {
  display: flex;
  justify-content: center; /* Espace entre les boutons */
  gap: 2rem;
}

.modal-buttons button {
  background-color: #007bff; /* Couleur du bouton */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 90px;
}

.modal-buttons button:hover {
  background-color: #0056b3; /* Couleur au survol */
}
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.modal-close:hover {
  color: red;
}
</style>
