<template>
  <v-container fluid>
    <!-- Main Content -->
    <div class="bon-livraison-page">
      <!-- Sidebar Navigation Drawer -->
      <v-navigation-drawer
        v-model="sidebarOpen"
        app
        style="
          position: absolute;
          top: 0;
          width: 251px;
          left: -35vh;
          box-shadow: none;
        "
      >
        <v-list>
          <v-list-item @click="closeSidebar">
            <v-list-item-content>
              <v-list-item-title>Close Sidebar</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Add a list to display deliveries -->
          <v-list-item-group>
            <v-list-item v-for="(delivery, index) in deliveries" :key="index">
              <v-list-item-content>
                <v-list-item-title>{{ delivery.client }}</v-list-item-title>
                <v-list-item-subtitle>{{ delivery.date }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

          <!-- You can add more navigation links or actions here -->
        </v-list>
      </v-navigation-drawer>

      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h3 class="page-title">Bon de Livraison</h3>

        <!-- Button to Toggle Sidebar -->
        <v-btn @click="toggleSidebar">Toggle Sidebar</v-btn>

        <v-menu offset-y>
          <v-btn color="primary" @click="downloadPDF">Télécharger PDF</v-btn>
        </v-menu>
      </v-row>

      <ul class="cards" id="bon-livraison-content">
        <!-- Display fetched deliveries -->
        <li v-for="(delivery, index) in deliveries" :key="index">
          <a href="" class="card">
            <img :src="delivery.image" class="card__image" alt="" />
            <div class="card__overlay">
              <div class="card__header">
                <svg class="card__arc" xmlns="http://www.w3.org/2000/svg">
                  <path />
                </svg>
                <img :src="delivery.thumb" class="card__thumb" alt="" />
                <div class="card__header-text">
                  <h3 class="card__title">{{ delivery.client }}</h3>
                  <span class="card__status">{{ delivery.date }}</span>
                </div>
              </div>
              <p class="card__description">{{ delivery.description }}</p>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </v-container>
</template>

<script>
import html2pdf from "html2pdf.js";
import { HTTP } from "@/axios"; // Ensure Axios is set up to make requests to your backend

export default {
  data() {
    return {
      sidebarOpen: false, // To control sidebar open/close
      deliveries: [], // Fetched delivery data
      clientId: "",
    };
  },
  async mounted() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData.length > 0) {
      this.clientId = userData[0]._id; // Use client ID from local storage
      console.log("Client ID set to:", this.clientId);
      await this.fetchDeliveries();
    } else {
      console.error("No user data found in local storage.");
    }
  },
  methods: {
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    closeSidebar() {
      this.sidebarOpen = false;
    },
    downloadPDF() {
      const element = document.getElementById("bon-livraison-content");
      const opt = {
        margin: 1,
        filename: "bon_livraison.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      html2pdf().from(element).set(opt).save();
    },
    async fetchDeliveries() {
      console.log("fetchDeliveries function called.");
      console.log(`Requesting tickets for client ID: ${this.clientId}`); // Log the client ID
      try {
        const response = await HTTP.get(`tickets/client/${this.clientId}`);
        console.log("API Response:", response.data); // Log the API response

        // Check if response.data is an array or object as expected
        if (Array.isArray(response.data)) {
          this.deliveries = response.data; // Set deliveries to response data
        } else {
          console.log("Unexpected response structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching deliveries:", error);
      }
    },
  },
};
</script>

<style scoped>
:root {
  --surface-color: #fff;
  --curve: 40;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Noto Sans JP", sans-serif;
  background-color: #fef8f8;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 4rem 5vw;
  padding: 0;
  list-style-type: none;
}

.card {
  position: relative;
  display: block;
  height: 86%;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
  width: 81%;
}

.card__image {
  width: 100%;
  height: auto;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: var(--surface-color);
  transform: translateY(100%);
  transition: 0.2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 2em;
  border-radius: 37px;
  background-color: white;
  transform: translateY(-100%);
  transition: 0.2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card__title {
  font-size: 1em;
  margin: 0 0 0.3em;
  color: #6a515e;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";
  font-size: 0.8em;
  color: #d7bdca;
}

.card__status {
  font-size: 0.8em;
  color: #d7bdca;
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: #d7bdca;
  font-family: "MockFlowFont";
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  background-color: white;
  border-radius: 38px;
}
</style>
