<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">Bon de réception</h1>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <router-link to="/addticket">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="secondary"
                class="text-capitalize button-shadow mr-1"
                >Ajouter Bon de réception</v-btn
              ></router-link
            >
          </template>
        </v-menu>
      </v-row>
      <v-row>
        <v-text-field
          v-model="search"
          value=""
          color="#2382bf"
          type="text"
          hide-no-data
          placeholder="Recherche Par nom"
          prepend-icon="mdi-database-search"
        ></v-text-field>
        <v-col cols="12">
          <v-card class="support-requests mx-1 mb-1">
            <v-card-title class="pa-6 pb-0 white--text info">
              <p class="white--text">Liste des Bon de réceptions</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="pl-9">
                    <tr>
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <!-- <th class="text-left pa-6">Numero</th> -->
                            <th class="text-left pa-6">Client</th>
                            <th class="text-left pa-4">adresse mail</th>
                            <th class="text-left pa-2">Telephone</th>
                            <th class="text-left pa-2">Date réception</th>
                            <th class="text-left pa-2">Effectuer</th>
                            <th>Fiche réception</th>
                          </v-expansion-panel-header>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(ticket, key) in clientTickets" :key="key">
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <td class="pa-0" style="width: 17%">
                              {{ ticket["client_id"].prenom }}
                            </td>
                            <td class="pa-0" style="width: 25%">
                              {{ ticket["client_id"].email }}
                            </td>
                            <td class="pa-0" style="width: 20%">
                              {{ ticket["client_id"].telephone }}
                            </td>
                            <td class="pa-0" style="width: 20%">
                              {{ ticket.datereception | formatDate }}
                            </td>
                            <template v-if="userRole === 'responsable'">
      <div class="select-container">
        <v-select
          v-model="affectépar[ticket._id]"
          :items="techniciens"
          item-text="name"
          item-value="_id"
          label="Affecter à un technicien"
          placeholder="Select a technician"
          :loading="loading"
          no-data-text="No technicians available"
          @change="onTechnicianChange(ticket._id)"
        ></v-select>
      </div>
    </template>

    <template v-else-if="userRole === 'admin'">
      <span style="width: 18%">{{ getTechnicianName(affectépar[ticket._id]) }}</span>
    </template>
                            <td class="pa-6" style="width: 15%">
                              <router-link
                                :to="{
                                  name: 'viewticket',
                                  params: { id: ticket._id },
                                }"
                              >
                                <v-icon large color="blue darken-2">
                                  mdi-eye</v-icon
                                >
                              </router-link>
                            </td>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-container>
                              <v-layout>
                                <v-flex xs12 sm12>
                                  <v-card hover>
                                    <v-card-title>
                                      <h4 class="info--text">
                                        <v-icon class="info--text"
                                          >mdi-ticket-account</v-icon
                                        >
                                        Liste des Tickets
                                      </h4>
                                    </v-card-title>
                                    <v-card-text>
                                      <v-simple-table class="text-left pa-6">
                                        <thead>
                                          <th>numero</th>
                                          <th>date reception</th>
                                          <th>produit</th>
                                          <th>statut affectation</th>
                                          <th>etat</th>
                                          <th>garantie</th>
                                          <th>Actions</th>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-for="(tickett, key) in ticket[
                                              'tickets'
                                            ]"
                                            :key="key"
                                          >
                                            <td style="text-align: left">
                                              {{ tickett.numero }}
                                            </td>

                                            <td>
                                              {{
                                                tickett.datereception
                                                  | formatDate
                                              }}
                                            </td>

                                            <td>
                                              {{ tickett.modeleappareil }}
                                            </td>

                                            <td
                                              v-if="tickett.affectépar == null"
                                            >
                                              <!-- <v-chip
                                                link
                                                color="danger"
                                                class="ma-2 ml-0"
                                                @click="dialog2 = true"
                                              >
                                                en attente
                                              </v-chip> -->
                                              <v-dialog width="900">
                                                <template
                                                  v-slot:activator="{
                                                    on,
                                                    attrs,
                                                  }"
                                                >
                                                  <v-chip
                                                    link
                                                    color="danger"
                                                    class="ma-2 ml-0"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                  >
                                                    en attente
                                                  </v-chip>
                                                </template>
                                                <template
                                                  v-slot:default="dialog2"
                                                >
                                                  <v-card>
                                                    <v-card-title
                                                      class="text-h5 grey lighten-2"
                                                    >
                                                      Affecter Ticket
                                                      {{ tickett.numero }} au
                                                      Technicien
                                                    </v-card-title>

                                                    <v-card-text>
                                                      <v-form v-model="valid">
                                                        <br />

                                                        Veuillez choisir le
                                                        technicien :
                                                        <br />
                                                        <br />
                                                        <v-container>
                                                          <v-card-text>
                                                            <v-select
                                                              outlined
                                                              label="Techniciens"
                                                              v-model="
                                                                affectépar
                                                              "
                                                              :items="
                                                                techniciens
                                                              "
                                                              item-text="name"
                                                              item-value="_id"
                                                              return-object
                                                            ></v-select>
                                                          </v-card-text>
                                                        </v-container>
                                                      </v-form>
                                                    </v-card-text>

                                                    <v-divider></v-divider>

                                                    <v-card-actions>
                                                      <v-spacer></v-spacer>
                                                      <v-btn
                                                        @click="
                                                          (dialog2.value = false),
                                                            addtechnicien(
                                                              tickett._id
                                                            )
                                                        "
                                                        style="float: right"
                                                        color="info"
                                                        :disabled="
                                                          valid == false
                                                        "
                                                      >
                                                        Affecter
                                                      </v-btn>
                                                      <v-btn
                                                        color="error"
                                                        @click="
                                                          dialog2.value = false
                                                        "
                                                      >
                                                        Annuler
                                                      </v-btn>
                                                    </v-card-actions>
                                                  </v-card>
                                                </template>
                                              </v-dialog>
                                            </td>
                                            <td v-else>
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                                affecté par
                                                {{ tickett.affectépar.name }}
                                              </v-chip>
                                            </td>

                                            <!-- réparation en cours et terminé  -->

                                            <td
                                              v-if="
                                                tickett.etat ===
                                                'réparation en cours'
                                              "
                                            >
                                              <v-dialog width="900">
                                                <template
                                                  v-slot:activator="{
                                                    on,
                                                    attrs,
                                                  }"
                                                >
                                                  <v-chip
                                                    link
                                                    color="danger"
                                                    class="ma-2 ml-0"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                  >
                                                    réparation en cours
                                                  </v-chip>
                                                </template>
                                                <template
                                                  v-slot:default="dialog4"
                                                >
                                                  <v-card>
                                                    <v-card-title
                                                      class="text-h5 grey lighten-2"
                                                    >
                                                      Voulez vous terminé la
                                                      réparation ?
                                                    </v-card-title>

                                                    <v-card-text>
                                                      <v-form v-model="valid">
                                                        <br />

                                                        Terminer la réparation

                                                        <br />
                                                        <br />
                                                      </v-form>
                                                    </v-card-text>

                                                    <v-divider></v-divider>

                                                    <v-card-actions>
                                                      <v-spacer></v-spacer>
                                                      <v-btn
                                                        @click="
                                                          (dialog4.value = false),
                                                            updatereparationticket(
                                                              tickett._id
                                                            )
                                                        "
                                                        style="float: right"
                                                        color="info"
                                                        :disabled="
                                                          valid == false
                                                        "
                                                      >
                                                        Modifier
                                                      </v-btn>
                                                      <v-btn
                                                        color="error"
                                                        @click="
                                                          dialog4.value = false
                                                        "
                                                      >
                                                        Annuler
                                                      </v-btn>
                                                    </v-card-actions>
                                                  </v-card>
                                                </template>
                                              </v-dialog>
                                            </td>

                                            <td
                                              v-if="
                                                tickett.etat ===
                                                'clôturé et décharge recu'
                                              "
                                            >
                                              <v-dialog width="500">
                                                <template
                                                  v-slot:activator="{
                                                    on,
                                                    attrs,
                                                  }"
                                                >
                                                  <v-chip
                                                    link
                                                    color="success"
                                                    class="ma-2 ml-0"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                  >
                                                    clôturé et décharge recu
                                                  </v-chip>
                                                </template>
                                                <template
                                                  v-slot:default="dialog6"
                                                >
                                                  <v-card>
                                                    <v-card-title
                                                      class="text-h5 grey lighten-2"
                                                    >
                                                      Voulez vous livré sans
                                                      décharge ?
                                                    </v-card-title>

                                                    <v-card-text>
                                                      <v-form v-model="valid">
                                                        <br />

                                                        Livré sans décharge ?

                                                        <br />
                                                        <br />
                                                      </v-form>
                                                    </v-card-text>

                                                    <v-divider></v-divider>

                                                    <v-card-actions>
                                                      <v-spacer></v-spacer>
                                                      <v-btn
                                                        @click="
                                                          (dialog6.value = false),
                                                            updatelivrerticket(
                                                              tickett._id
                                                            )
                                                        "
                                                        style="float: right"
                                                        color="info"
                                                        :disabled="
                                                          valid == false
                                                        "
                                                      >
                                                        Livrer
                                                      </v-btn>
                                                      <v-btn
                                                        color="error"
                                                        @click="
                                                          dialog6.value = false
                                                        "
                                                      >
                                                        Annuler
                                                      </v-btn>
                                                    </v-card-actions>
                                                  </v-card>
                                                </template>
                                              </v-dialog>
                                            </td>

                                            <td
                                              v-if="
                                                tickett.etat ===
                                                'réparation terminé'
                                              "
                                            >
                                              <v-dialog width="500">
                                                <template
                                                  v-slot:activator="{
                                                    on,
                                                    attrs,
                                                  }"
                                                >
                                                  <v-chip
                                                    link
                                                    color="success"
                                                    class="ma-2 ml-0"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                  >
                                                    réparation terminé
                                                  </v-chip>
                                                </template>
                                                <template
                                                  v-slot:default="dialog5"
                                                >
                                                  <v-card>
                                                    <v-card-title
                                                      class="text-h5 grey lighten-2"
                                                    >
                                                      Voulez vous cloturé ?
                                                    </v-card-title>

                                                    <v-card-text>
                                                      <v-form v-model="valid">
                                                        <br />

                                                        Terminer la cloture ?

                                                        <br />
                                                        <br />
                                                      </v-form>
                                                    </v-card-text>

                                                    <v-divider></v-divider>

                                                    <v-card-actions>
                                                      <v-spacer></v-spacer>
                                                      <v-btn
                                                        @click="
                                                          (dialog5.value = false),
                                                            updateclotureticket(
                                                              tickett._id
                                                            )
                                                        "
                                                        style="float: right"
                                                        color="info"
                                                        :disabled="
                                                          valid == false
                                                        "
                                                      >
                                                        Modifier
                                                      </v-btn>
                                                      <v-btn
                                                        color="error"
                                                        @click="
                                                          dialog5.value = false
                                                        "
                                                      >
                                                        Annuler
                                                      </v-btn>
                                                    </v-card-actions>
                                                  </v-card>
                                                </template>
                                              </v-dialog>
                                            </td>

                                            <td
                                              v-if="
                                                tickett.etat ===
                                                'livré sans décharge'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="success"
                                                class="ma-2 ml-0"
                                              >
                                                Livré sans décharge
                                              </v-chip>
                                            </td>

                                            <td
                                              v-if="
                                                tickett.etat === 'en_attente_pr'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                                en attente PR
                                              </v-chip>
                                            </td>

                                            <td
                                              v-if="
                                                tickett.etat ===
                                                'devis_en_attente_accord_reception_client'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                                devis en attente accord
                                                réception client
                                              </v-chip>
                                            </td>
                                            <td
                                              v-if="tickett.etat === 'en cours'"
                                            >
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                                diagnostic en cours
                                              </v-chip>
                                            </td>

                                            <td
                                              v-if="
                                                tickett.etat ===
                                                'devis_attente_accord_client_final'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                                devis en attente accord client
                                                final
                                              </v-chip>
                                            </td>

                                            <td
                                              v-if="
                                                tickett.etat ===
                                                'en attente de facturation'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                                en attente de facturation
                                              </v-chip>
                                            </td>

                                            <td
                                              v-if="
                                                tickett.etat ==
                                                'cloturesansreponse'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                                clôturer sans réponse
                                              </v-chip>
                                            </td>

                                            <td
                                              v-if="
                                                tickett.etat ==
                                                'refusparréception'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                                clôturer sans réponse
                                              </v-chip>
                                            </td>

                                            <td
                                              v-if="
                                                tickett.etat ===
                                                'clotureetdechargerecu'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="success"
                                                class="ma-2 ml-0"
                                              >
                                                clôturé et décharge reçu
                                              </v-chip>
                                            </td>

                                            <td
                                              v-if="tickett.etat === 'ouverte'"
                                              class="ma-3 ml-10"
                                            >
                                              &nbsp;
                                              <v-chip link color="secondary">
                                                Ouverte
                                              </v-chip>
                                            </td>

                                            <td
                                              v-if="
                                                tickett.etat_garantie ===
                                                'garantie'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="success"
                                                class="ma-2 ml-0"
                                              >
                                                garantie
                                              </v-chip>
                                            </td>
                                            <td
                                              v-else-if="
                                                tickett.etat_garantie ===
                                                'nn garantie'
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="secondary"
                                                class="ma-2 ml-0"
                                              >
                                                nn garantie
                                              </v-chip>
                                            </td>
                                            <td
                                              v-else-if="
                                                tickett.etat_garantie === ''
                                              "
                                            >
                                              <v-chip
                                                link
                                                color="warning"
                                                class="ma-2 ml-0"
                                              >
                                                pas encore
                                              </v-chip>
                                            </td>

                                            <td>
                                              <router-link
                                                :to="{
                                                  name: 'rapportticket',
                                                  params: { id: tickett._id },
                                                }"
                                              >
                                                <v-icon large color="green ">
                                                  mdi-book
                                                </v-icon>
                                              </router-link>
                                            </td>

                                            <td>
                                              <v-btn
                                                icon
                                                v-if="
                                                  tickett.affectépar == null
                                                "
                                                :to="{
                                                  name: 'editticket',
                                                  params: { id: tickett._id },
                                                }"
                                              >
                                                <v-icon>mdi-pencil</v-icon>
                                              </v-btn>

                                              <!-- Bouton pour supprimer avec modal -->
                                              <v-btn
                                                icon
                                                v-if="
                                                  tickett.affectépar == null
                                                "
                                                @click="dialog = true"
                                              >
                                                <v-icon>mdi-delete</v-icon>
                                              </v-btn>
                                              <v-btn
                                                icon
                                                v-if="
                                                  tickett.affectépar == null
                                                "
                                                @click="
                                                  openBarcodeDialog(
                                                    tickett.numero
                                                  )
                                                "
                                              >
                                                <v-icon>mdi-printer</v-icon>
                                              </v-btn>

                                              <!-- Dialog pour afficher le code-barres -->
                                              <v-dialog
                                                v-model="dialogcode"
                                                max-width="420"
                                              >
                                                <v-card>
                                                  <v-card-title>
                                                    Code-barres pour le ticket
                                                    {{ ticketNumber }}
                                                  </v-card-title>
                                                  <v-card-text>
                                                    <div
                                                      v-if="dialogcode"
                                                      class="dialog"
                                                    >
                                                      <div
                                                        class="barcode-container"
                                                      >
                                                        <vue-barcode
                                                          :value="ticketNumber"
                                                          format="CODE128"
                                                          :displayValue="true"
                                                        />
                                                      </div>
                                                    </div>

                                                    <!-- Conteneur pour l'impression -->
                                                  </v-card-text>
                                                  <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                      color="blue"
                                                      text
                                                      @click="printBarcode"
                                                    >
                                                      Imprimer
                                                    </v-btn>
                                                    <v-btn
                                                      color="grey"
                                                      text
                                                      @click="
                                                        dialogcode = false
                                                      "
                                                    >
                                                      Fermer
                                                    </v-btn>
                                                  </v-card-actions>
                                                </v-card>
                                              </v-dialog>
                                              <!-- contenu a imprimer -->
                                              <div
                                                id="print-container"
                                                class="print-container"
                                              >
                                                <div class="barcode-container">
                                                  <vue-barcode
                                                    :value="ticketNumber"
                                                    format="CODE128"
                                                    :displayValue="true"
                                                  />
                                                </div>
                                                <div class="barcode-container">
                                                  <vue-barcode
                                                    :value="ticketNumber"
                                                    format="CODE128"
                                                    :displayValue="true"
                                                  />
                                                </div>
                                                <div class="barcode-container">
                                                  <vue-barcode
                                                    :value="ticketNumber"
                                                    format="CODE128"
                                                    :displayValue="true"
                                                  />
                                                </div>
                                              </div>

                                              <!-- Modal de confirmation de suppression -->
                                              <v-dialog
                                                v-model="dialog"
                                                persistent
                                                max-width="390"
                                              >
                                                <v-card>
                                                  <v-card-title class="text-h5">
                                                    Supprimer Ticket?
                                                  </v-card-title>
                                                  <v-card-text>
                                                    Voulez-vous supprimer ce
                                                    ticket ?
                                                  </v-card-text>
                                                  <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                      color="blue"
                                                      text
                                                      @click="dialog = false"
                                                    >
                                                      Annuler
                                                    </v-btn>
                                                    <v-btn
                                                      color="red"
                                                      text
                                                      @click="
                                                        deleteticket(
                                                          tickett._id
                                                        )
                                                      "
                                                    >
                                                      Confirmer
                                                    </v-btn>
                                                  </v-card-actions>
                                                </v-card>
                                              </v-dialog>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </v-simple-table>
                                    </v-card-text>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <div class="pagination">
                <button @click="previousPage" :disabled="currentPage === 1">
                  Previous
                </button>
                <span>{{ currentPage }}</span>
                <button
                  @click="nextPage"
                  :disabled="currentPage === totalPages"
                >
                  Next
                </button>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { HTTP } from "@/axios";
import VueBarcode from "vue-barcode";
import printJS from "print-js";
export default {
  components: {
    VueBarcode,
  },
  data() {
    return {
      clientTickets: [],
      techniciens: [],
      affectépar: {},
      userRole: null,
      techniciansLoading: false, // Track loading state separately
      dialogcode: false,
      ticketNumber: "",

      itemsPerPage: 5,
      currentPage: 1,
      tickets: [],
      search: "",

      dialog: false,
      user: {},
      dialog2: true,
      dialog4: true,

      listetickets: {},
      champRules: [(v) => !!v || "Ce champ est obligatoire"],
    };
  },

  computed: {
    searchFunction() {
      return this.tickets.filter((item) => {
        console.log(item.tickets);
        return (
          item.client_id["name"]
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1
        );
      });
    },

    totalPages() {
      return Math.ceil(this.tickets.length / this.itemsPerPage);
    },
    displayedTickets() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      // Log the calculated indices
      console.log(`Start Index: ${startIndex}, End Index: ${endIndex}`);

      // Slice the tickets array
      const displayed = this.tickets.slice(startIndex, endIndex);

      // Log the displayed tickets
      console.log("Displayed Tickets:", displayed);

      return displayed;
    },
  },
  created() {
    this.gettickets();
    this.getuser();
    console.log("User Role:", this.userRole); // Add a log to verify if userRole is 'responsable'
    this.getTechniciens();
  },
  mounted() {
    this.fetchTickets();
    this.fetchTechnicians();
    this.initializeTechnicianAssignments();
  },

  methods: {
    getTechnicianName(technicianId) {
      // Helper function to get the technician's name by ID
      const technician = this.techniciens.find((t) => t._id === technicianId);
      return technician ? technician.name : "No technician assigned";
    },
    async fetchTechnicians() {
      this.loading = true; // Set loading to true while fetching data
      try {
        const response = await HTTP.get("users/getusers"); // Fetch all users
        console.log("API Response:", response.data); // Log response data
        this.techniciens = response.data.filter(
          (user) => user.role === "technicien"
        ); // Filter for technicians
        console.log("Filtered Technicians:", this.techniciens); // Log filtered technicians
      } catch (error) {
        console.error("Error fetching technicians:", error);
      } finally {
        this.loading = false; // Stop loading regardless of success or error
      }
    },
    initializeTechnicianAssignments() {
      this.clientTickets.forEach((ticket) => {
        // Check if the ticket has a technician assigned
        if (
          ticket.tickets &&
          ticket.tickets.length > 0 &&
          ticket.tickets[0].affectépar
        ) {
          // Extract the technician ID from the nested affectépar object
          this.affectépar[ticket._id] = ticket.tickets[0].affectépar._id; // Set the technician ID
        }
      });
    },
    onTechnicianChange(ticketId) {
      // Call your existing method to handle the technician assignment
      this.addtechnicien(ticketId);
    },
    async fetchTickets() {
      try {
        const response = await HTTP.get("/tickets/getticket"); // Adjust the API endpoint if necessary
        this.clientTickets = response.data; // Assuming this is where you store the fetched tickets
        console.log("Tickets fetched successfully:", this.clientTickets);
        this.initializeTechnicianAssignments(); // Initialize assignments after fetching tickets
      } catch (error) {
        console.error(
          "Error fetching tickets:",
          error.response ? error.response.data : error
        );
      }
    },
    getTechniciens() {
      this.techniciansLoading = true; // Start loading
      HTTP.get("users/getusers")
        .then((response) => {
          this.techniciens = response.data.filter(
            (user) => user.role === "technicien"
          );
        })
        .finally(() => {
          this.techniciansLoading = false; // Stop loading when data is fetched
        })
        .catch((err) => {
          console.error("Error fetching technicians:", err);
        });
    },
    getuser() {
      // Retrieve userData from localStorage
      const userData = localStorage.getItem("userData");

      if (!userData) {
        console.error("userData not found in localStorage");
        return;
      }

      try {
        // Parse the userData string into an object
        const parsedUserData = JSON.parse(userData);

        // Check if parsedUserData is an array and has at least one user
        if (Array.isArray(parsedUserData) && parsedUserData.length > 0) {
          this.user = parsedUserData[0]; // Assign the first user object
          this.userRole = this.user.role; // Get the user role
          console.log("User role:", this.userRole);
        } else {
          console.warn("No valid user data found:", parsedUserData);
        }
      } catch (error) {
        console.error("Error parsing userData:", error);
      }
    },
    openBarcodeDialog(ticketNumber) {
      this.ticketNumber = ticketNumber;
      this.dialogcode = true;
    },
    printBarcode() {
      this.dialogcode = false;

      // Afficher temporairement le conteneur d'impression
      const printContainer = document.getElementById("print-container");
      printContainer.style.display = "block";

      this.$nextTick(() => {
        setTimeout(() => {
          printJS({
            printable: "print-container",
            type: "html",
            scanStyles: false,
            onPrintDialogClose: () => {
              // Réinitialiser l'état après l'impression
              this.ticketNumber = ""; // Réinitialiser le numéro du ticket
              printContainer.style.display = "none"; // Re-cacher le conteneur d'impression
            },
          });
        }, 500); // Ajustez le délai si nécessaire
      });
    },

    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    handleAction(ticket) {
      // Handle the action based on the ticket data
      // For example, you can perform a specific action when the button is clicked
      console.log("Action clicked for ticket:", ticket);
    },
    getechniciens() {
      HTTP.get("users/getechniciens")
        .then((response) => {
          this.techniciens = response.data;
          console.log(this.techniciens);
        })

        .then((err) => console.log(err));
    },

    gettickets() {
      HTTP.get("tickets/getticket")
        .then((response) => {
          this.tickets = response.data;
          console.log("Tickets fetched successfully:", this.tickets);

          // Validate and assign
          if (Array.isArray(this.tickets)) {
            this.tickets.forEach((ticket) => {
              if (ticket && ticket._id) {
                this.affectépar[ticket._id] = null; // Initialize with null
              } else {
                console.warn("Ticket does not have a valid _id:", ticket);
              }
            });
          } else {
            console.error(
              "Expected an array of tickets, but got:",
              this.tickets
            );
          }
        })
        .catch((err) => {
          console.error("Error fetching tickets:", err);
        });
    },
    deleteticket(id) {
      HTTP.delete("tickets/deleteticket/" + id).then((response) => {
        console.log(response);
        this.$toast.success("Ticket supprimé!", {
          position: "top-right",
        });

        this.gettickets();
      });
    },

    addtechnicien(clientTicketId) {
      // Ensure clientTickets is defined and is an array
      if (!this.clientTickets || !Array.isArray(this.clientTickets)) {
        console.error("clientTickets is not defined or is not an array");
        return; // Exit if clientTickets is undefined or not an array
      }

      // Find the specific ticket entry using the clientTicketId
      const ticketEntry = this.clientTickets.find(
        (ticket) => ticket._id === clientTicketId
      );

      // Check if the ticketEntry was found
      if (!ticketEntry) {
        console.error("No ticket found for clientTicketId:", clientTicketId);
        return; // Exit if no ticket found
      }

      // Log the found ticketEntry for debugging
      console.log("Found ticketEntry:", ticketEntry);

      // Ensure the tickets array exists and has at least one ticket
      if (!ticketEntry.tickets || ticketEntry.tickets.length === 0) {
        console.error("No tickets associated with this ticketEntry");
        return; // Exit if no tickets found
      }

      // Extract the actual ticket ID from the tickets array
      const ticketId = ticketEntry.tickets[0]._id; // Access the ID of the first ticket

      // Assuming you have a way to get the technician ID based on the clientTicketId
      const technicienId = this.affectépar[clientTicketId]; // Ensure this is the correct ID

      // Log for debugging
      console.log("Technician ID for ticket ID", ticketId, ":", technicienId);

      // Ensure technicienId is defined before proceeding
      if (!technicienId) {
        console.error(
          "Technician ID is not defined for ticket ID:",
          clientTicketId
        );
        return; // Exit if no technician is selected
      }

      // Form the correct URL for the request
      const url = `http://localhost:3010/api/tickets/${ticketId}/assign-technician`;

      // Send the technician's ID to the backend
      HTTP.put(url, { technicienId })
        .then((response) => {
          console.log("Technician assigned successfully", response.data);
        })
        .catch((error) => {
          console.error(
            "Error assigning technician",
            error.response ? error.response.data : error
          );
        });
    },
    updateclotureticket(ticket) {
      console.log(ticket);
      let oldticket = {
        _id: ticket,
      };

      HTTP.put("tickets/updateclotureticket", oldticket).then(() => {
        this.$toast.success("Ticket cloturé et décharge reçu!", {
          position: "top-right",
        });

        location.reload();
      });
    },

    updatelivrerticket(ticket) {
      let oldticket = {
        _id: ticket,
      };

      HTTP.put("tickets/updatelivrerticket", oldticket).then(() => {
        this.$toast.success("Ticket livré sans décharge!", {
          position: "top-right",
        });

        location.reload();
      });
    },

    updatereparationticket(ticket) {
      console.log(ticket);
      let oldticket = {
        _id: ticket,
      };

      HTTP.put("tickets/updatereparationticket", oldticket).then(() => {
        this.$toast.success("Ticket Réparé!", {
          position: "top-right",
        });

        location.reload();
      });
    },
  },
};
</script>

<style src="./Dashboard.scss" lang="scss" />
<style scoped>
#barcode-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.barcode-container {
  margin-bottom: 1rem;
}

/* Cacher le conteneur d'impression par défaut */
#print-container {
  display: none !important; /* Ajoutez !important pour forcer l'application du style */
}

/* Assurez-vous que le conteneur d'impression reste caché même lors de l'impression */
@media print {
  #print-container {
    display: none !important; /* Ajoutez !important pour forcer l'application du style */
  }
}
.select-container {
  display: flex;
  justify-content: flex-start; /* Aligns items to the start of the flex container */
  width: 250px; /* Set your desired width */
}

/* Optional: Set the same height for the select and other elements if necessary */
.select-container .v-input {
  height: 56px; /* Set the height to match Vuetify default */
}
</style>
