<template>
  <v-app>
    <v-container class="support-requests mx-1 mb-1 pa-5">
      <!-- Champ de recherche et table -->
      <v-col cols="4" md="4">
        <v-text-field
          v-model="search"
          color="#2382bf"
          type="text"
          hide-no-data
          placeholder="Recherche livreur"
          prepend-icon="mdi-database-search"
          dense
        ></v-text-field>
      </v-col>
      <v-data-table
        :headers="headers"
        :items="livreurs"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat class="pa-2 grey lighten-4 toolbar-border">
            <v-toolbar-title>Liste des Livreurs</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="primary mb-2" @click="openAddModal"
              >Ajouter Livreur</v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="me-2" @click="openEditModal(item)">mdi-pencil</v-icon>
          <v-icon @click="confirmDeleteLivreur(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>

      <!-- Modal pour ajouter un livreur -->
      <v-dialog v-model="addModal" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Ajouter Livreur</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="newLivreur.nom"
                label="Nom"
                :rules="[rules.required]"
                required
              ></v-text-field>
              <v-text-field
                v-model="newLivreur.telephone"
                label="Téléphone"
                :rules="[rules.required]"
                required
              ></v-text-field>
              <v-text-field
                v-model="newLivreur.email"
                label="Email"
                :rules="[rules.required, rules.email]"
                required
              ></v-text-field>
              <v-text-field
                v-model="newLivreur.vehicule"
                label="Véhicule"
                :rules="[rules.required]"
                required
              ></v-text-field>
              <v-text-field
                v-model="newLivreur.immatriculation"
                label="Immatriculation"
                :rules="[rules.required]"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeAddModal"
              >Annuler</v-btn
            >
            <v-btn color="blue darken-1" text @click="addLivreur"
              >Ajouter</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal pour confirmer la suppression -->
      <v-dialog v-model="confirmDeleteModal" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Confirmer la suppression</span>
          </v-card-title>
          <v-card-text>
            Êtes-vous sûr de vouloir supprimer ce livreur ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeConfirmDelete"
              >Annuler</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteLivreur"
              >Confirmer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal pour modifier un livreur -->
      <v-dialog v-model="editModal" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Modifier Livreur</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="editForm" v-model="validEdit" lazy-validation>
              <v-text-field
                v-model="selectedLivreur.nom"
                label="Nom"
                :rules="[rules.required]"
                required
                :disabled="!selectedLivreur"
              ></v-text-field>
              <v-text-field
                v-model="selectedLivreur.telephone"
                label="Téléphone"
                :rules="[rules.required]"
                required
                :disabled="!selectedLivreur"
              ></v-text-field>
              <v-text-field
                v-model="selectedLivreur.email"
                label="Email"
                :rules="[rules.required, rules.email]"
                required
                :disabled="!selectedLivreur"
              ></v-text-field>
              <v-text-field
                v-model="selectedLivreur.vehicule"
                label="Véhicule"
                :rules="[rules.required]"
                required
                :disabled="!selectedLivreur"
              ></v-text-field>
              <v-text-field
                v-model="selectedLivreur.immatriculation"
                label="Immatriculation"
                :rules="[rules.required]"
                required
                :disabled="!selectedLivreur"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditModal"
              >Annuler</v-btn
            >
            <v-btn color="blue darken-1" text @click="updateLivreur"
              >Enregistrer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import { HTTP } from "@/axios";

export default {
  data() {
    return {
      search: "",

      valid: false,
      validEdit: false,
      addModal: false,
      editModal: false,
      confirmDeleteModal: false,
      livreurs: [], // Liste des livreurs
      selectedLivreur: {}, // Initialiser comme objet vide
      newLivreur: {
        nom: "",
        telephone: "",
        email: "",
        vehicule: "",
        immatriculation: "",
      },
      headers: [
        { text: "Immatriculation", value: "immatriculation" },
        { text: "Nom", value: "nom" },
        { text: "Téléphone", value: "telephone" },
        { text: "Email", value: "email" },
        { text: "Véhicule", value: "vehicule" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Ce champ est requis.",
        email: (value) => {
          const pattern = /.+@.+\..+/;
          return pattern.test(value) || "E-mail invalide.";
        },
      },
    };
  },
  created() {
    this.getLivreurs();
  },
  methods: {
    openAddModal() {
      this.addModal = true;
    },
    closeAddModal() {
      this.addModal = false;
    },
    openEditModal(livreur) {
      this.selectedLivreur = { ...livreur }; // Crée une copie pour éviter les modifications non souhaitées
      this.editModal = true;
    },
    closeEditModal() {
      this.editModal = false;
      this.selectedLivreur = {}; // Réinitialiser à un objet vide
    },
    confirmDeleteLivreur(livreur) {
      this.selectedLivreur = livreur;
      this.confirmDeleteModal = true;
    },
    closeConfirmDelete() {
      this.confirmDeleteModal = false;
      this.selectedLivreur = {}; // Réinitialiser à un objet vide
    },
    getLivreurs() {
      HTTP.get("livreurs/tous")
        .then((response) => {
          this.livreurs = response.data;
        })
        .catch((err) => console.log(err));
    },
    async addLivreur() {
      if (this.$refs.form.validate()) {
        try {
          const response = await HTTP.post("livreurs/ajout", this.newLivreur);
          this.$toast.success("Livreur ajouté avec succès !", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });
          this.getLivreurs();
          this.closeAddModal();
        } catch (error) {
          this.$toast.error(
            "Une erreur est survenue lors de l'ajout du livreur.",
            {
              position: "top-right",
              timeout: 2000,
              closeOnClick: true,
            }
          );
          console.error("Erreur lors de l'ajout du livreur:", error);
        }
      }
    },
    async updateLivreur() {
      if (this.$refs.editForm.validate() && this.selectedLivreur) {
        try {
          await HTTP.put(
            `livreurs/modifier/${this.selectedLivreur._id}`,
            this.selectedLivreur
          );

          this.$toast.success("Livreur modifié avec succès !", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });

          this.getLivreurs();
          this.closeEditModal();
        } catch (error) {
          this.$toast.error(
            "Une erreur est survenue lors de la modification du livreur.",
            {
              position: "top-right",
              timeout: 2000,
              closeOnClick: true,
            }
          );
          console.error("Erreur lors de la modification du livreur:", error);
        }
      }
    },
    async deleteLivreur() {
      if (this.selectedLivreur) {
        try {
          await HTTP.delete(`livreurs/effacer/${this.selectedLivreur._id}`);

          this.$toast.success("Livreur supprimé avec succès !", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });

          this.getLivreurs();
          this.closeConfirmDelete();
        } catch (error) {
          this.$toast.error(
            "Une erreur est survenue lors de la suppression du livreur.",
            {
              position: "top-right",
              timeout: 2000,
              closeOnClick: true,
            }
          );
          console.error("Erreur lors de la suppression du livreur:", error);
        }
      }
    },
  },
};
</script>

<style scoped>
.custom-toolbar {
  background-color: #9e9e9e;
}
</style>
