<template>
    <v-app>
      <v-container class="responsable mx-1 mb-1 pa-5">
        <v-col cols="4" md="4">
          <v-text-field
            v-model="search"
            color="#2382bf"
            type="text"
            hide-no-data
            placeholder="Recherche Responsable"
            prepend-icon="mdi-database-search"
            dense
          ></v-text-field>
        </v-col>
        
        <v-data-table
          :headers="headers"
          :items="filteredResponsables"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat class="pa-2 grey lighten-4 toolbar-border">
              <v-toolbar-title>Liste des Responsables</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary mb-2" @click="openAddModal">
                Ajouter Responsable
              </v-btn>
            </v-toolbar>
          </template>
  
          <template v-slot:item.actions="{ item }">
            <v-icon class="me-2" @click="openEditModal(item)">mdi-pencil</v-icon>
            <v-icon @click="confirmDeleteResponsable(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
  
        <!-- Modal for adding a responsable -->
        <v-dialog v-model="addModal" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Ajouter Responsable</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="newResponsable.nom"
                  label="Nom"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="newResponsable.prenom"
                  label="Prénom"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="newResponsable.email"
                  label="Email"
                  :rules="[rules.required, rules.email]"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="newResponsable.telephone"
                  label="Téléphone"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="newResponsable.adresse"
                  label="Adresse"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeAddModal">Annuler</v-btn>
              <v-btn color="blue darken-1" text @click="addResponsable">Ajouter</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  
        <!-- Modal for confirming deletion -->
        <v-dialog v-model="confirmDeleteModal" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Confirmer la suppression</span>
            </v-card-title>
            <v-card-text>
              Êtes-vous sûr de vouloir supprimer ce responsable ?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeConfirmDelete">Annuler</v-btn>
              <v-btn color="blue darken-1" text @click="deleteResponsable">Confirmer</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  
        <!-- Modal for editing a responsable -->
        <v-dialog v-model="editModal" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Modifier Responsable</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="editForm" v-model="validEdit" lazy-validation>
                <v-text-field
                  v-model="selectedResponsable.nom"
                  label="Nom"
                  :rules="[rules.required]"
                  required
                  :disabled="!selectedResponsable"
                ></v-text-field>
                <v-text-field
                  v-model="selectedResponsable.prenom"
                  label="Prénom"
                  :rules="[rules.required]"
                  required
                  :disabled="!selectedResponsable"
                ></v-text-field>
                <v-text-field
                  v-model="selectedResponsable.email"
                  label="Email"
                  :rules="[rules.required, rules.email]"
                  required
                  :disabled="!selectedResponsable"
                ></v-text-field>
                <v-text-field
                  v-model="selectedResponsable.telephone"
                  label="Téléphone"
                  :rules="[rules.required]"
                  required
                  :disabled="!selectedResponsable"
                ></v-text-field>
                <v-text-field
                  v-model="selectedResponsable.adresse"
                  label="Adresse"
                  :rules="[rules.required]"
                  required
                  :disabled="!selectedResponsable"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditModal">Annuler</v-btn>
              <v-btn color="blue darken-1" text @click="updateResponsable">Enregistrer</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-app>
  </template>
  
  <script>
  import { HTTP } from "@/axios"; // Adjust the import path if necessary
  
  export default {
    data() {
      return {
        search: "",
        valid: false,
        validEdit: false,
        addModal: false,
        editModal: false,
        confirmDeleteModal: false,
        responsables: [],
        selectedResponsable: {},
        newResponsable: {
          nom: "",
          prenom: "",
          email: "",
          adresse: "",
          telephone: "",
        },
        headers: [
          { text: "Nom", value: "nom" },
          { text: "Prénom", value: "prenom" },
          { text: "Email", value: "email" },
          { text: "Téléphone", value: "telephone" },
          { text: "Actions", value: "actions", sortable: false },
        ],
        rules: {
          required: (value) => !!value || "Ce champ est requis.",
          email: (value) => {
            const emailPattern =
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailPattern.test(value) || "Email invalide.";
          },
        },
      };
    },
    created() {
      this.getResponsables(); // Fetch responsables when the component is created
    },
    computed: {
      filteredResponsables() {
        if (!this.search) {
          return this.responsables;
        }
        const searchTerm = this.search.toLowerCase();
        return this.responsables.filter((responsable) => {
          return (
            responsable.nom.toLowerCase().includes(searchTerm) ||
            responsable.prenom.toLowerCase().includes(searchTerm) ||
            responsable.email.toLowerCase().includes(searchTerm) ||
            responsable.telephone.toLowerCase().includes(searchTerm)
          );
        });
      },
    },
    methods: {
      openAddModal() {
        this.newResponsable = {
            typeUser: "Responsable",
          nom: "",
          prenom: "",
          email: "",
          adresse: "",
          telephone: "",
        };
        this.addModal = true;
      },
      closeAddModal() {
        this.addModal = false;
      },
      openEditModal(responsable) {
        this.selectedResponsable = { ...responsable };
        this.editModal = true;
      },
      closeEditModal() {
        this.editModal = false;
        this.selectedResponsable = {};
      },
      confirmDeleteResponsable(responsable) {
        this.selectedResponsable = responsable;
        this.confirmDeleteModal = true;
      },
      closeConfirmDelete() {
        this.confirmDeleteModal = false;
        this.selectedResponsable = {};
      },
      getResponsables() {
        HTTP.get("responsables/responsables") // Replace with the correct endpoint
          .then((response) => {
            this.responsables = response.data;
          })
          .catch((err) => console.error(err));
      },
      async addResponsable() {
  if (this.$refs.form.validate()) {
    const responsableData = {
      typeUser: this.newResponsable.typeUser, // Ensure this is set
      email: this.newResponsable.email,
      nom: this.newResponsable.nom,
      adresse: this.newResponsable.adresse,
      telephone: this.newResponsable.telephone,
      prenom: this.newResponsable.prenom,
    };

    console.log('Data to be sent:', responsableData); // Check this log

    try {
      const response = await HTTP.post('responsables/addresponsable', responsableData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Response:', response.data);

      // Show a success message
      this.$toast.success(response.data.message); // Use toast for success message
      this.getResponsables(); // Refresh the list of responsables
      this.closeAddModal(); // Close the modal after successful addition

    } catch (error) {
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Status code:', error.response.status);
        this.$toast.error("Erreur lors de l'ajout du responsable."); // Handle error
      } else {
        console.error('Error:', error.message);
        this.$toast.error("Erreur lors de l'ajout du responsable."); // Handle error
      }
    }
  }
},
async updateResponsable() {
  if (this.$refs.editForm.validate()) {
    console.log("Updating Responsable with ID:", this.selectedResponsable._id);
    try {
      // Send the update request
      const response = await HTTP.put(
        `http://localhost:3010/api/responsables/${this.selectedResponsable._id}`, // Complete URL
        {
          nom: this.selectedResponsable.nom,
          prenom: this.selectedResponsable.prenom,
          email: this.selectedResponsable.email,
          adresse: this.selectedResponsable.adresse,
          telephone: this.selectedResponsable.telephone,
          typeUser: this.selectedResponsable.typeUser, // Include typeUser if necessary
        }
      );

      // Show success message
      this.$toast.success(response.data.message); // Success message
      this.getResponsables(); // Refresh the list of responsables
      this.closeEditModal(); // Close the modal after successful update
    } catch (error) {
      console.error('Error updating responsable:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Status code:', error.response.status);
        this.$toast.error(error.response.data.message || "Erreur lors de la mise à jour du responsable.");
      } else {
        console.error('Error:', error.message);
        this.$toast.error("Erreur lors de la mise à jour du responsable.");
      }
    }
  }
},
async deleteResponsable() {
    try {
        // Ensure you have the correct ID from the selectedResponsable
        if (!this.selectedResponsable || !this.selectedResponsable._id) {
            throw new Error("Responsable ID is undefined.");
        }

        // Make the DELETE request to the correct endpoint
        await HTTP.delete(`responsables/${this.selectedResponsable._id}`); // Adjusted endpoint

        // Show success toast notification
        this.$toast.success("Responsable supprimé avec succès !", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
        });

        // Refresh the list of responsables
        this.getResponsables(); 

        // Close the delete confirmation modal
        this.closeConfirmDelete(); 
    } catch (error) {
        // Log the error to the console
        console.error("Erreur lors de la suppression du responsable:", error);
        
        // Show error toast notification
        this.$toast.error("Erreur lors de la suppression du responsable.", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
        });
    }
},
    },
  };
  </script>
  
  <style scoped>
  .responsable {
    /* Add your custom styles here */
  }
  
  .toolbar-border {
    border-bottom: 1px solid #e0e0e0;
  }
  </style>