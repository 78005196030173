<template>
  <v-app>
    <v-container class="support-requests mx-1 mb-1 pa-5">
      <!-- Search field and data table -->
      <v-col cols="4" md="4">
        <v-text-field
          v-model="search"
          color="#2382bf"
          type="text"
          hide-no-data
          placeholder="Recherche Représentant"
          prepend-icon="mdi-database-search"
          dense
        ></v-text-field>
      </v-col>
      <v-data-table
        :headers="headers"
        :items="representants"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat class="pa-2 grey lighten-4 toolbar-border">
            <v-toolbar-title>Liste des Représentants</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="primary mb-2" @click="openAddModal"
              >Ajouter Représentant</v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="me-2" @click="openEditModal(item)">mdi-pencil</v-icon>
          <v-icon @click="confirmDeleteRepresentant(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>

      <!-- Modal for adding a representative -->
      <v-dialog v-model="addModal" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Ajouter Représentant</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-select
                v-model="newRepresentant.typeUser"
                :items="representantTypes"
                label="Type de Représentant"
                :rules="[rules.required]"
                required
              ></v-select>

              <v-text-field
                v-model="newRepresentant.nom"
                label="Nom"
                :rules="[rules.required]"
                required
              ></v-text-field>
              <v-text-field
                v-model="newRepresentant.prenom"
                label="Prénom"
                :rules="[rules.required]"
                required
              ></v-text-field>
              <v-text-field
                v-model="newRepresentant.email"
                label="Email"
                :rules="[rules.required, rules.email]"
                required
              ></v-text-field>
              <v-text-field
                v-model="newRepresentant.adresse"
                label="Adresse"
                :rules="[rules.required]"
                required
              ></v-text-field>
              <v-text-field
                v-model="newRepresentant.telephone"
                label="Téléphone"
                :rules="[rules.required]"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeAddModal"
              >Annuler</v-btn
            >
            <v-btn color="blue darken-1" text @click="addRepresentant"
              >Ajouter</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal for confirming deletion -->
      <v-dialog v-model="confirmDeleteModal" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Confirmer la suppression</span>
          </v-card-title>
          <v-card-text>
            Êtes-vous sûr de vouloir supprimer ce représentant ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeConfirmDelete"
              >Annuler</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteRepresentant"
              >Confirmer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal for editing a representative -->
      <v-dialog v-model="editModal" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Modifier Représentant</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="editForm" v-model="validEdit" lazy-validation>
              <v-select
                v-model="selectedRepresentant.typeUser"
                :items="representantTypes"
                label="Type de Représentant"
                :rules="[rules.required]"
                required
                :disabled="!selectedRepresentant"
              ></v-select>
              <v-text-field
                v-model="selectedRepresentant.nom"
                label="Nom"
                :rules="[rules.required]"
                required
                :disabled="!selectedRepresentant"
              ></v-text-field>
              <v-text-field
                v-model="selectedRepresentant.prenom"
                label="Prénom"
                :rules="[rules.required]"
                required
                :disabled="!selectedRepresentant"
              ></v-text-field>
              <v-text-field
                v-model="selectedRepresentant.email"
                label="Email"
                :rules="[rules.required, rules.email]"
                required
                :disabled="!selectedRepresentant"
              ></v-text-field>
              <v-text-field
                v-model="selectedRepresentant.adresse"
                label="Adresse"
                :rules="[rules.required]"
                required
                :disabled="!selectedRepresentant"
              ></v-text-field>
              <v-text-field
                v-model="selectedRepresentant.telephone"
                label="Téléphone"
                :rules="[rules.required]"
                required
                :disabled="!selectedRepresentant"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditModal"
              >Annuler</v-btn
            >
            <v-btn color="blue darken-1" text @click="updateRepresentant"
              >Enregistrer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import { HTTP } from "@/axios"; // Adjust the import path if necessary

export default {
  data() {
    return {
      search: "",
      valid: false,
      validEdit: false,
      addModal: false,
      editModal: false,
      confirmDeleteModal: false,
      representants: [], // List of representatives
      selectedRepresentant: {}, // Selected representative for editing or deletion
      newRepresentant: {
        typeUser: "",
        nom: "",
        email: "",
        adresse: "",
        telephone: "",
        prenom: "",
      },
      headers: [
        { text: "Nom", value: "nom" },
        { text: "Email", value: "email" },
        { text: "Téléphone", value: "telephone" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      representantTypes: ["Représentant Client", "Représentant Société"], // Dropdown options
      rules: {
        required: (value) => !!value || "Ce champ est requis.",
        email: (value) => {
          const emailPattern =
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          return emailPattern.test(value) || "Email invalide.";
        },
      },
    };
  },
  created() {
    this.getRepresentants(); // Fetch representatives when the component is created
  },
  computed: {
    filteredRepresentants() {
      // If no search input, return all representants
      if (!this.search) {
        return this.representants;
      }
      // Convert search string to lowercase for case-insensitive matching
      const searchTerm = this.search.toLowerCase();
      // Filter the representants array based on search criteria
      return this.representants.filter((representant) => {
        return (
          representant.nom.toLowerCase().includes(searchTerm) ||
          representant.email.toLowerCase().includes(searchTerm) ||
          representant.telephone.toLowerCase().includes(searchTerm)
        );
      });
    },
  },
  methods: {
    openAddModal() {
      this.newRepresentant = {
        typeUser: "",
        nom: "",
        email: "",
        adresse: "",
        telephone: "",
        prenom: "",
      };
      this.addModal = true;
    },
    closeAddModal() {
      this.addModal = false;
    },
    openEditModal(representant) {
      this.selectedRepresentant = { ...representant }; // Create a copy
      this.editModal = true;
    },
    closeEditModal() {
      this.editModal = false;
      this.selectedRepresentant = {}; // Reset to empty object
    },
    confirmDeleteRepresentant(representant) {
      this.selectedRepresentant = representant;
      this.confirmDeleteModal = true;
    },
    closeConfirmDelete() {
      this.confirmDeleteModal = false;
      this.selectedRepresentant = {}; // Reset to empty object
    },
    getRepresentants() {
      HTTP.get("representants/representants")
        .then((response) => {
          this.representants = response.data;
        })
        .catch((err) => console.error(err));
    },
    async addRepresentant() {
      if (this.$refs.form.validate()) {
        // Create a plain object for the data to be sent
        const plainRepresentant = {
          typeUser: this.newRepresentant.typeUser,
          nom: this.newRepresentant.nom,
          email: this.newRepresentant.email,
          adresse: this.newRepresentant.adresse,
          telephone: this.newRepresentant.telephone,
          prenom: this.newRepresentant.prenom,
        };

        console.log("Data to be sent:", plainRepresentant); // Log the plain object

        try {
          const response = await HTTP.post(
            "representants/addrepresentant",
            plainRepresentant
          );

          this.$toast.success("Représentant ajouté avec succès !", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });

          this.getRepresentants(); // Refresh the list of representatives
          this.closeAddModal(); // Close the modal
        } catch (error) {
          if (error.response) {
            console.error("Response data:", error.response.data); // Log response data
          } else {
            console.error("Error:", error.message);
          }
          this.$toast.error("Erreur lors de l'ajout du représentant.", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });
        }
      }
    },
    
    async updateRepresentant() {
      if (this.$refs.editForm.validate()) {
        try {
          console.log("Updating data:", this.selectedRepresentant);
          await HTTP.put(
            `representants/representants/${this.selectedRepresentant._id}`, // Use the correct URL
            this.selectedRepresentant
          );

          this.$toast.success("Représentant mis à jour avec succès !", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });

          this.getRepresentants(); // Refresh the list of representatives
          this.closeEditModal(); // Close the edit modal
        } catch (error) {
          console.error(
            "Erreur lors de la mise à jour du représentant:",
            error
          );
          this.$toast.error("Erreur lors de la mise à jour du représentant.", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });
        }
      }
    },
    async deleteRepresentant() {
      try {
        await HTTP.delete(
          `representants/representants/${this.selectedRepresentant._id}` // Correct endpoint
        );

        this.$toast.success("Représentant supprimé avec succès !", {
          position: "top-right",
          timeout: 2000,
          closeOnClick: true,
        });

        this.getRepresentants(); // Refresh the list of representatives
        this.closeConfirmDelete(); // Close the delete confirmation modal
      } catch (error) {
        console.error("Erreur lors de la suppression du représentant:", error);
        this.$toast.error("Erreur lors de la suppression du représentant.", {
          position: "top-right",
          timeout: 2000,
          closeOnClick: true,
        });
      }
    },
  },
};
</script>

<style scoped>
.custom-toolbar {
  background-color: #9e9e9e;
}
</style>
