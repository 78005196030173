<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters class="d-flex justify-space-between mt-30 mb-6">
        <h1 class="page-title">Ajouter Bon de Réception</h1>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <router-link to="/tickets">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                class="text-capitalize button-shadow mr-1"
                >Retour</v-btn
              >
            </router-link>
          </template>
        </v-menu>
      </v-row>
      <v-row>
        <v-form v-model="valid1" style="width: 100%">
          <v-card class="pa-8" width="100%">
            <!-- slot step 1  -->

            <div id="clientdata" v-show="!clientisHidden">
              <v-container fluid class="custom-card">
                <v-row>
                  <!-- Colonne pour le groupe de boutons radio -->
                  <v-col cols="12" md="4">
                    <div class="product-type-container">
                      <span class="product-type-label">Type produit</span>
                      <v-radio-group
                        @change="handleRadioChange"
                        v-model="clientType"
                      >
                        <v-radio
                          color="primary"
                          label="Particulier"
                          value="particulier"
                        ></v-radio>
                        <v-radio
                          color="primary"
                          label="Société"
                          value="societe"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                  </v-col>

                  <v-col cols="12" md="4"> </v-col>
                </v-row>
                <v-form ref="form">
                  <v-row class="d-flex align-center position-relative">
                    <v-col
                      v-if="!nouveauClient"
                      class="d-flex position-relative"
                      cols="12"
                      sm="5"
                    >
                      <!-- Select Component -->
                      <v-select
                        :items="filteredClients"
                        v-model="client"
                        label="Sélectionner un client"
                        :item-text="
                          (item) => `${item.name} ${item.prenom} (${item.code})`
                        "
                        item-value="email"
                        outlined
                        class="position-relative"
                      ></v-select>

                      <!-- Tooltip with Button Component -->
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            to="/addClientTicket"
                            icon
                            class="ml-2 greenbutton position-absolute"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon style="color: white">mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Ajouter nouveau client</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <div class="bordered-row" v-if="clientType === 'societe'">
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="societe.matricule_fiscale"
                          label="Matricule fiscale"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="societe.RNE"
                          :rules="societeRules"
                          label="RNE"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="societe.raisonSociale"
                          :rules="societeRules"
                          label="Raison Sociale"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="societe.fax"
                          :rules="societeRules"
                          label="Fax"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="societe.MF"
                          :rules="societeRules"
                          label="MF"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="societe.web"
                          :rules="societeRules"
                          label="Web"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="ticket.adresse"
                          :rules="adresseRules"
                          label="Adresse"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="bordered-row">
                    <div
                      class="ticket-type-label"
                      v-if="clientType === 'societe'"
                    >
                      <span style="display: flex; text-align: start"
                        >Contact</span
                      >
                    </div>
                    <v-row style="margin-top: 19px">
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="ticket.name"
                          :rules="nameRules"
                          :counter="30"
                          :label="
                            clientType === 'societe' ? 'Nom société' : 'Nom'
                          "
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        md="4"
                        v-if="clientType === 'particulier'"
                      >
                        <v-text-field
                          v-model="ticket.prenom"
                          :rules="prenomRules"
                          :counter="30"
                          label="Prenom"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="ticket.email"
                          :rules="[
                            (v) => !!v || 'E-mail est obligatoire',
                            (v) => /.+@.+/.test(v) || 'E-mail doit etre valide',
                            existingMail,
                          ]"
                          :messages="mailMessage"
                          label="E-mail"
                          @keyup="emailExists(ticket.email)"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="ticket.telephone"
                          :rules="telephoneRules"
                          label="Telephone"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4" v-if="clientType === 'societe'">
                        <v-text-field
                          v-model="ticket.post"
                          label="Post"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4"> </v-col>
                      <v-col cols="12" md="4"> </v-col>
                      <v-col cols="12" md="4"> </v-col>
                      <v-col cols="12" md="4"> </v-col>
                      <v-col cols="12" md="4"> </v-col>
                      <v-col cols="12" md="4"> </v-col>
                      <v-col cols="12" md="4"> </v-col>
                      <v-col cols="12" md="4"> </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
              <div class="class-center">
                <v-btn
                  class="float-right mt-3"
                  color="primary"
                  :disabled="!valid1"
                  :loading="loading"
                  @click="hideandshow()"
                >
                  <svg-icon
                    type="mdi"
                    :path="mdiArrowRightThinCircleOutline"
                  ></svg-icon>
                  Suivant
                </v-btn>
              </div>
            </div>
            <div class="text-center" v-show="!ticketisHidden">
              <v-dialog width="900" v-model="dialog2" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <div class="dashboard-page">
                    <div class="ticket-container">
                      <div class="ticket-item">
                        <svg-icon
                          type="mdi"
                          :path="mdiAccountTagOutline"
                          class="ticket-icon"
                        ></svg-icon>
                        <span class="ticket-label">Code Client</span>
                        <span>{{ ticket.IdClient }}</span>
                      </div>

                      <div class="ticket-item">
                        <svg-icon
                          type="mdi"
                          :path="mdiAccountMultipleOutline"
                          class="ticket-icon"
                        ></svg-icon>
                        <span class="ticket-label">Nom Client</span>
                        <span>{{ ticket.name }} {{ ticket.prenom }}</span>
                      </div>

                      <div class="ticket-item">
                        <svg-icon
                          type="mdi"
                          :path="mdiTagOutline"
                          class="ticket-icon"
                        ></svg-icon>
                        <span class="ticket-label">ID Bon de Réception</span>
                        <span>{{ Bon_de_reception_ID }}</span>
                      </div>

                      <div class="ticket-item">
                        <svg-icon
                          type="mdi"
                          :path="mdiCalendarMonthOutline"
                          class="ticket-icon"
                        ></svg-icon>
                        <span class="ticket-label">Date</span>
                        <span>{{ currentDate }}</span>
                      </div>

                      <div class="ticket-item">
                        <vue-qrcode
                          :value="'Rec' + Bon_de_reception_ID"
                          size="80"
                        ></vue-qrcode>
                      </div>
                    </div>

                    <div class="class-center">
                      <v-btn
                        class="my-custom-btn"
                        v-bind="attrs"
                        v-on="on"
                        type="button"
                        color="info"
                        style="width: 170px; text-transform: none"
                      >
                        <svg-icon
                          type="mdi"
                          :path="mdiPlusCircleOutline"
                          style="margin-right: 8px"
                        ></svg-icon>
                        Ajouter Ticket
                      </v-btn>
                    </div>
                  </div>
                </template>

                <!-- modal add ticket -->

                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="text-h5 info">
                      Ajouter Ticket {{ Num_Ticket }}
                    </v-card-title>

                    <v-card-text>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <br />
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                outlined
                                label="Numéro ticket"
                                v-bind:value="'Tic' + Num_Ticket"
                                disabled
                              ></v-text-field>
                            </v-col>

                            <v-col
                              cols="12"
                              sm="6"
                              md="6"
                              id="revendeur"
                              style="display: block"
                            >
                              <v-text-field
                                outlined
                                :label="
                                  clientType === 'societe'
                                    ? 'Nom société'
                                    : 'Nom'
                                "
                                v-model="ticket.name"
                                id="revendeure"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col
                              v-if="clientType === 'societe'"
                              cols="12"
                              sm="6"
                              md="6"
                              id="datefacture"
                              style="display: block"
                            >
                              <v-text-field
                                outlined
                                label="date facture"
                                type="date"
                                v-model="ticket.datefacture"
                                id="datefacturee"
                              ></v-text-field>
                            </v-col>

                            <v-col
                              v-if="clientType === 'societe'"
                              cols="12"
                              sm="6"
                              md="6"
                              id="numerofacture"
                              style="display: block"
                            >
                              <v-text-field
                                label="numero facture"
                                outlined
                                id="numerofacturee"
                                v-model="ticket.numerofacture"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                label="num lot/numserie"
                                outlined
                                :rules="champRules"
                                v-model="ticket.numlotounumserie"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="6">
                              <v-select
                                outlined
                                v-model="ticket.typeproduit"
                                :items="typeproduits"
                                label="Type produit"
                                item-value="_id"
                                item-text="nom"
                                :rules="champRules"
                              ></v-select>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    ripple
                                    @click="openAddModal"
                                    icon
                                    class="ml-2 bluebutton position-absolute"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon style="color: white"
                                      >mdi-plus</v-icon
                                    >
                                  </v-btn>
                                </template>
                                <span>Ajouter type produit</span>
                              </v-tooltip>
                            </v-col>

                            <!-- Modal for Adding Type Produit -->
                            <v-dialog
                              v-model="addModalproduit"
                              max-width="600px"
                            >
                              <v-card>
                                <v-card-title>
                                  <span class="headline"
                                    >Ajouter Type de Produit</span
                                  >
                                </v-card-title>
                                <v-card-text>
                                  <v-form
                                    ref="form"
                                    v-model="validproduit"
                                    lazy-validation
                                  >
                                    <v-text-field
                                      v-model="newTypeProduit.nom"
                                      label="Nom"
                                      :rules="[rules.required]"
                                      required
                                    ></v-text-field>
                                    <v-text-field
                                      v-model="newTypeProduit.description"
                                      label="Description"
                                      :rules="[rules.required]"
                                    ></v-text-field>
                                    <v-text-field
                                      v-model="newTypeProduit.categorie"
                                      label="Catégorie"
                                      :rules="[rules.required]"
                                      required
                                    ></v-text-field>
                                  </v-form>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeAddModal"
                                    >Annuler</v-btn
                                  >
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="addTypeProduit"
                                    >Ajouter</v-btn
                                  >
                                </v-card-actions>
                              </v-card>
                            </v-dialog>

                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                outlined
                                label="Marque appareil"
                                v-model="ticket.marqueappareil"
                                :rules="champRules"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                outlined
                                :rules="champRules"
                                label="Modéle appareil"
                                v-model="ticket.modeleappareil"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <v-select
                                outlined
                                v-model="ticket.livreur"
                                :items="livreurs"
                                label="Livreur"
                                item-value="_id"
                                item-text="nom"
                                :rules="champRules"
                              >
                                <template v-slot:append-item>
                                  <v-list-item
                                    ripple
                                    @click="openAddLivreurModal"
                                    class="d-flex align-center"
                                  >
                                    <v-list-item-icon class="mr-0">
                                      <v-icon>mdi-plus</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        >Autre</v-list-item-title
                                      >
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-select>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    ripple
                                    @click="openAddLivreurModal"
                                    icon
                                    class="ml-2 bluebutton position-absolute"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon style="color: white"
                                      >mdi-plus</v-icon
                                    >
                                  </v-btn>
                                </template>
                                <span>Ajouter nouveau livreur</span>
                              </v-tooltip>
                            </v-col>

                            <!-- Modale d'ajout de livreur -->
                            <v-dialog
                              v-model="addLivreurModal"
                              max-width="600px"
                            >
                              <v-card>
                                <v-card-title>
                                  <span class="headline"
                                    >Ajouter un nouveau livreur</span
                                  >
                                </v-card-title>
                                <v-card-text>
                                  <v-form
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation
                                  >
                                    <v-text-field
                                      v-model="newLivreur.nom"
                                      label="Nom"
                                      :rules="[rules.required]"
                                      required
                                    ></v-text-field>
                                    <v-text-field
                                      v-model="newLivreur.telephone"
                                      label="Téléphone"
                                      :rules="[rules.required]"
                                      required
                                    ></v-text-field>
                                    <v-text-field
                                      v-model="newLivreur.email"
                                      label="Email"
                                      :rules="[rules.required, rules.email]"
                                      required
                                    ></v-text-field>
                                    <v-text-field
                                      v-model="newLivreur.vehicule"
                                      label="Véhicule"
                                      :rules="[rules.required]"
                                      required
                                    ></v-text-field>
                                    <v-text-field
                                      v-model="newLivreur.immatriculation"
                                      label="Immatriculation"
                                      :rules="[rules.required]"
                                      required
                                    ></v-text-field>
                                  </v-form>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeAddLivreurModal"
                                    >Annuler</v-btn
                                  >
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="addLivreur"
                                    >Ajouter</v-btn
                                  >
                                </v-card-actions>
                              </v-card>
                            </v-dialog>

                            <v-col cols="12" sm="6" md="6">
                              <div class="representant-container">
                                <v-select
                                  outlined
                                  v-model="ticket.representant"
                                  :items="representants"
                                  label="Représentant"
                                  item-value="_id"
                                  item-text="nom"
                                  :rules="champRules"
                                ></v-select>

                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      ripple
                                      @click="openAddModalRepresentant"
                                      icon
                                      class="representbutton"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon style="color: white"
                                        >mdi-plus</v-icon
                                      >
                                    </v-btn>
                                  </template>
                                  <span>Ajouter Représentant</span>
                                </v-tooltip>
                              </div>
                            </v-col>
                            <v-dialog
                              v-model="addModalRepresentant"
                              max-width="600px"
                            >
                              <v-card>
                                <v-card-title>
                                  <span class="headline"
                                    >Ajouter Représentant</span
                                  >
                                </v-card-title>
                                <v-card-text>
                                  <v-form
                                    ref="form"
                                    v-model="validRepresentant"
                                    lazy-validation
                                  >
                                    <v-select
                                      v-model="newRepresentant.typeUser"
                                      :items="representantTypes"
                                      label="Type de Représentant"
                                      :rules="[rules.required]"
                                      required
                                    ></v-select>

                                    <v-text-field
                                      v-model="newRepresentant.nom"
                                      label="Nom"
                                      :rules="[rules.required]"
                                      required
                                    ></v-text-field>
                                    <v-text-field
                                      v-model="newRepresentant.prenom"
                                      label="Prénom"
                                      :rules="[rules.required]"
                                      required
                                    ></v-text-field>
                                    <v-text-field
                                      v-model="newRepresentant.email"
                                      label="Email"
                                      :rules="[rules.required, rules.email]"
                                      required
                                    ></v-text-field>
                                    <v-text-field
                                      v-model="newRepresentant.adresse"
                                      label="Adresse"
                                      :rules="[rules.required]"
                                      required
                                    ></v-text-field>
                                    <v-text-field
                                      v-model="newRepresentant.telephone"
                                      label="Téléphone"
                                      :rules="[rules.required]"
                                      required
                                    ></v-text-field>
                                  </v-form>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeAddModalRepresentant"
                                    >Annuler</v-btn
                                  >
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="addNewRepresentant"
                                    >Ajouter</v-btn
                                  >
                                </v-card-actions>
                              </v-card>
                            </v-dialog>

                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <div class="accessoire-container">
                                  <v-select
                                    label="Accessoire"
                                    outlined
                                    v-model="ticket.accessoires"
                                    :items="accessoires"
                                    :menu-props="{ maxHeight: '400' }"
                                    item-text="libelle"
                                    item-value="_id"
                                    multiple
                                    persistent-hint
                                    class="accessoire-select"
                                  ></v-select>

                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        ripple
                                        @click="openAddModalAccessoir"
                                        icon
                                        class="accessoirebutton"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon style="color: white"
                                          >mdi-plus</v-icon
                                        >
                                      </v-btn>
                                    </template>
                                    <span>Ajouter type accessoire</span>
                                  </v-tooltip>
                                </div>
                              </v-col>

                              <v-dialog
                                v-model="addModalaccessoir"
                                max-width="600px"
                              >
                                <v-card>
                                  <v-card-title>
                                    <span class="headline"
                                      >Ajouter Accessoire</span
                                    >
                                  </v-card-title>
                                  <v-card-text>
                                    <v-form ref="form" v-model="validaccess">
                                      <v-text-field
                                        v-model="newAccessoire.libelle"
                                        label="Libelle"
                                        :rules="[rules.required]"
                                        required
                                      ></v-text-field>
                                      <v-text-field
                                        v-model="newAccessoire.reference"
                                        label="Reference"
                                        :rules="[rules.required]"
                                        required
                                      ></v-text-field>
                                      <v-text-field
                                        v-model="newAccessoire.produit"
                                        label="Produit"
                                        :rules="[rules.required]"
                                        required
                                      ></v-text-field>
                                    </v-form>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="closeAddModalAccessoir"
                                      >Annuler</v-btn
                                    >
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="addNewAccessoire"
                                      >Ajouter</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                              <v-col cols="24" sm="16" md="16">
                                <div class="product-type-container">
                                  <span class="product-garantie-label"
                                    >Garantie</span
                                  >
                                  <v-radio-group
                                    v-model="ticket.etat_garantie"
                                    row
                                  >
                                    <v-radio
                                      color="primary"
                                      label="Oui"
                                      :value="true"
                                    ></v-radio>
                                    <v-radio
                                      color="primary"
                                      label="Non"
                                      :value="false"
                                    ></v-radio>
                                  </v-radio-group>
                                </div>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col
                                v-if="
                                  clientType === 'particulier' &&
                                  ticket.etat_garantie
                                "
                                cols="12"
                                sm="6"
                                md="6"
                                id="datefacture"
                                style="display: block"
                              >
                                <v-text-field
                                  outlined
                                  label="date facture"
                                  type="date"
                                  v-model="ticket.datefacture"
                                  id="datefacturee"
                                ></v-text-field>
                              </v-col>

                              <v-col
                                v-if="
                                  clientType === 'particulier' &&
                                  ticket.etat_garantie
                                "
                                cols="12"
                                sm="6"
                                md="6"
                                id="numerofacture"
                                style="display: block"
                              >
                                <v-text-field
                                  label="numero facture"
                                  outlined
                                  id="numerofacturee"
                                  v-model="ticket.numerofacture"
                                ></v-text-field> </v-col
                            ></v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-textarea
                                v-model="ticket.pannesreclames"
                                outlined
                                name="input-7-4"
                                label="Pannes Réclammés"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="AddTicketCodeabar(Num_Ticket)"
                        style="float: right"
                        text
                        class="info"
                        :disabled="!valid"
                      >
                        <v-icon> mdi-plus-circle-multiple-outline</v-icon>
                        &nbsp; Ticket
                      </v-btn>

                      <v-btn
                        style="float: right"
                        text
                        class="success"
                        :disabled="!valid"
                        @click="
                          (dialog.value = false), AddTicketCodeabar(Num_Ticket)
                        "
                      >
                        <v-icon> mdi-check-all</v-icon>
                        Enregistrer
                      </v-btn>
                      <v-btn text class="error" @click="dialog.value = false">
                        Annuler
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
            <v-dialog v-model="dialogcode" max-width="420">
              <v-card>
                <v-card-title>
                  Code-barres pour le ticket
                  {{ Num_Ticket }}
                </v-card-title>
                <v-card-text>
                  <div v-if="dialogcode" class="dialog">
                    <div class="barcode-container">
                      <vue-barcode
                        :value="Num_Ticket"
                        format="CODE128"
                        :displayValue="true"
                      />
                    </div>
                  </div>

                  <!-- Conteneur pour l'impression -->
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue" text @click="printBarcode">
                    Imprimer
                  </v-btn>
                  <v-btn color="grey" text @click="dialogcode = false">
                    Fermer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- contenu a imprimer -->
            <div id="print-container" class="print-container">
              <div class="barcode-container">
                <vue-barcode
                  :value="Num_Ticket"
                  format="CODE128"
                  :displayValue="true"
                />
              </div>
              <div class="barcode-container">
                <vue-barcode
                  :value="Num_Ticket"
                  format="CODE128"
                  :displayValue="true"
                />
              </div>
              <div class="barcode-container">
                <vue-barcode
                  :value="Num_Ticket"
                  format="CODE128"
                  :displayValue="true"
                />
              </div>
            </div>
            <br />

            <br />
            <br />
            <div id="table" style="display: none">
              <table class="table-main">
                <thead>
                  <tr class="tabletitle">
                    <th>Numéro</th>

                    <th>Marque appareil</th>
                    <th>Modéle appareil</th>
                    <th>Livreur</th>
                    <th>Représentant</th>
                    <th>Modifier</th>
                    <th>Supprimer</th>
                  </tr>
                </thead>
                <tr
                  class="list-item"
                  v-for="(ticket, key) in arrayticket.tickets"
                  :key="key"
                >
                  <td data-label="Type" class="tableitem">
                    {{ ticket.ticket.numeroticket }}
                  </td>

                  <td data-label="Quantity" class="tableitem">
                    {{ ticket.ticket.marqueappareil }}
                  </td>
                  <td data-label="Unit Price" class="tableitem">
                    {{ ticket.ticket.modeleappareil }}
                  </td>
                  <td data-label="Taxable Amount" class="tableitem">
                    {{ getLivreurName(ticket.ticket.livreur) }}
                    <!-- Display livreur's name -->
                  </td>
                  <td data-label="Tax Code" class="tableitem">
                    {{ getRepresentantName(ticket.ticket.representant) }}
                    <!-- Display representant's name -->
                  </td>
                  <!-- modifier ticket -->
                  <td>
                    <v-dialog width="900">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-3"
                          text
                          icon
                          v-bind="attrs"
                          v-on="on"
                          color="green lighten-2"
                        >
                          <v-icon>mdi-tooltip-edit</v-icon>
                        </v-btn>
                      </template>

                      <!-- modal add ticket -->

                      <template v-slot:default="dialog1">
                        <v-card>
                          <v-card-title class="text-h5 info">
                            Modifier bon de reception
                            {{ ticket.ticket.numeroticket }}
                          </v-card-title>

                          <v-card-text>
                            <v-form ref="form" v-model="valid" lazy-validation>
                              <br />
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      outlined
                                      label="Numéro ticket"
                                      v-bind:value="ticket.ticket.numeroticket"
                                      disabled
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      outlined
                                      label="Type Revendeur"
                                      v-model="ticket.ticket.typerevendeur"
                                      :rules="champRules"
                                      :items="items"
                                    ></v-select>
                                  </v-col>

                                  <v-col
                                    v-if="
                                      ticket.ticket.typerevendeur ==
                                      'Client final'
                                    "
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    id="clientfinal"
                                    style="display: block"
                                  >
                                    <v-select
                                      outlined
                                      v-model="ticket.ticket.revendeur"
                                      :items="revendeurs"
                                      label="client final"
                                      item-text="name"
                                      item-value="name"
                                      :rules="champRules"
                                      id="clientfinale"
                                    ></v-select>
                                  </v-col>

                                  <v-col
                                    v-else
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    id="revendeur"
                                    style="display: block"
                                  >
                                    <v-text-field
                                      outlined
                                      label="revendeur"
                                      v-model="ticket.ticket.revendeur"
                                      id="revendeure"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    id="datefacture"
                                    style="display: block"
                                  >
                                    <v-text-field
                                      outlined
                                      label="date facture"
                                      type="date"
                                      v-model="ticket.ticket.datefacture"
                                      id="datefacturee"
                                      style="display: block"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    id="numerofacture"
                                    style="display: block"
                                  >
                                    <v-text-field
                                      label="numero facture"
                                      outlined
                                      id="numerofacturee"
                                      v-model="ticket.ticket.numerofacture"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      label="num lot/numserie"
                                      outlined
                                      :rules="champRules"
                                      v-model="ticket.ticket.numlotounumserie"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      outlined
                                      v-model="ticket.ticket.typeproduit"
                                      :items="typeproduits"
                                      label="Type produit"
                                      :rules="champRules"
                                      item-text="produit"
                                      item-value="produit"
                                    ></v-select>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      outlined
                                      label="Marque appareil"
                                      v-model="ticket.ticket.marqueappareil"
                                      :rules="champRules"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      outlined
                                      :rules="champRules"
                                      label="Modéle appareil"
                                      v-model="ticket.ticket.modeleappareil"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      outlined
                                      :rules="champRules"
                                      label="Livreur"
                                      v-model="ticket.ticket.livreur"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      outlined
                                      :rules="champRules"
                                      label="Representant"
                                      v-model="ticket.ticket.representant"
                                    ></v-text-field>
                                  </v-col>

                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      outlined
                                      v-model="ticket.ticket.accessoires"
                                      :items="accessoireess"
                                      :menu-props="{ maxHeight: '600' }"
                                      label="liste des accessoires"
                                      item-text="libelle"
                                      item-value="libelle"
                                      multiple
                                      persistent-hint
                                    ></v-select>
                                  </v-col>

                                  <v-col cols="12" sm="12" md="12">
                                    <v-textarea
                                      v-model="ticket.ticket.pannesreclames"
                                      outlined
                                      name="input-7-4"
                                      label="Pannes Réclammés"
                                    ></v-textarea>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-form>
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              @click="editnewticket()"
                              style="float: right"
                              text
                              color="info"
                              :disabled="!valid"
                            >
                              Modifier
                            </v-btn>
                            <v-btn
                              text
                              color="red"
                              @click="dialog1.value = false"
                            >
                              Annuler
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                  </td>
                  <td>
                    <v-dialog width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-3"
                          text
                          icon
                          v-bind="attrs"
                          v-on="on"
                          color="red lighten-2"
                        >
                          <v-icon>mdi-tooltip-remove</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:default="dialog">
                        <v-card>
                          <v-card-title class="text-h5">
                            Supprimer Ticket?
                          </v-card-title>
                          <v-card-text
                            >Voulez vous supprimer cet ticket ?</v-card-text
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue"
                              text
                              @click="dialog.value = false"
                            >
                              Annuler
                            </v-btn>
                            <v-btn
                              color="red"
                              text
                              @click="
                                deleteticket(ticket.ticket.numeroticket),
                                  (dialog.value = false)
                              "
                            >
                              Confirmer
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                  </td>
                </tr>
              </table>
              <br />
              <br />

              <v-btn
                style="float: right"
                color="success"
                @click="delay()"
                :disabled="disabled"
              >
                <v-icon>mdi mdi-arrow-right</v-icon>
                &nbsp;Enregistrer Tous
              </v-btn>

              <br />
              <br />
            </div>

            <!--End Table-->
          </v-card>
        </v-form>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { HTTP } from "@/axios";
import SvgIcon from "@jamescoyle/vue-icon";
import VueQrcode from "@chenfengyuan/vue-qrcode";

import {
  mdiAccountMultipleOutline,
  mdiAccountTagOutline,
  mdiCalendarMonthOutline,
  mdiTagOutline,
  mdiArrowRightThinCircleOutline,
  mdiPlusCircleOutline,
} from "@mdi/js";
import VueBarcode from "vue-barcode";
import Swal from "sweetalert2";
import printJS from "print-js";
import { data } from "jquery";

export default {
  name: "Dashboard",
  components: {
    VueBarcode,
    SvgIcon,
    VueQrcode,
  },

  data() {
    return {
      representantTypes: ["Représentant Client", "Représentant Société"],
      representants: [],
      dialogcode: false,
      ticketClientId: "",
      garantie: false,
      addLivreurModal: false,
      addModalaccessoir: false,
      addModalRepresentant: false,
      validRepresentant: false,
      newLivreur: {
        nom: "",
        telephone: "",
        email: "",
        vehicule: "",
        immatriculation: "",
      },
      newRepresentant: {
        typeUser: "",
        nom: "",
        prenom: "",
        email: "",
        adresse: "",
        telephone: "",
      },
      headers: [
        { text: "Nom", value: "nom" },
        { text: "Téléphone", value: "telephone" },
        { text: "Email", value: "email" },
        { text: "Véhicule", value: "vehicule" },
        { text: "Immatriculation", value: "immatriculation" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Ce champ est requis.",
        email: (value) => {
          const pattern = /.+@.+\..+/;
          return pattern.test(value) || "E-mail invalide.";
        },
      },
      addModalproduit: false,
      mdiAccountMultipleOutline: mdiAccountMultipleOutline,
      mdiArrowRightThinCircleOutline: mdiArrowRightThinCircleOutline,
      mdiCalendarMonthOutline: mdiCalendarMonthOutline,
      mdiAccountTagOutline: mdiAccountTagOutline,
      mdiTagOutline: mdiTagOutline,
      mdiPlusCircleOutline: mdiPlusCircleOutline,
      currentDate: new Date().toLocaleDateString(),
      loading: false,
      societe: {
        raisonSociale: "",
        matricule_fiscale: "",
        RNE: "",
        MF: "",
        web: "",
        fax: "",
      },
      societeRules: [(v) => !!v || "Ce champ est obligatoire"],

      selectedClient: null,

      clientType: "particulier",

      ticketisHidden: true,
      clientisHidden: false,
      dialog: false,
      disabled: false,
      timeout: null,

      dialog1: false,
      dialog2: false,
      validproduit: false,
      validaccess: false,
      valid: false,
      valid1: false,
      arraynumbertickets: [],
      lastticket: "",

      items: ["Client final", "Revendeur"],
      typerevendeur: ["Client final", "Revendeur"],
      typeproduits: [],
      newTypeProduit: {
        nom: "",
        description: "",
        categorie: "",
      },
      client: "",
      clientsold: [],
      clientfound: {},

      filteredClients: [],
      champRules: [
        (v) => !!v || "Ce champ est obligatoire",
        (v) => (v && v.length <= 30) || "Champ must be less than 30 characters",
      ],
      nameRules: [
        (v) => !!v || "Name est obligatoire",
        (v) => (v && v.length <= 30) || "Name must be less than 30 characters",
      ],
      prenomRules: [
        (v) => !!v || "prenom est obligatoire",
        (v) =>
          (v && v.length <= 30) || "prenom must be less than 30 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail est obligatoire",
        (v) =>
          /^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,}$/.test(v) ||
          "E-mail doit être valide",
      ],

      existingMail: () => true || this.mailMessage,
      mailMessage: "",

      telephoneRules: [
        (v) => !!v || "telephone est obligatoire",
        (v) => (v && v.length <= 8) || "telephone doit etre 8 characters",
      ],
      adresseRules: [(v) => !!v || "adresse est obligatoire"],

      arrayticket: {
        client: {},

        tickets: [],
      },

      newticket: {
        client: {},
        ticket: [],
      },
      revendeursold: [],
      clients: [],
      revendeurs: [],
      accessoirees: [],
      accessoireess: [],
      livreurs: [],

      i: 1,
      ticket: {
        IdClient: "",
        name: "",
        pannesreclames: "",
        prenom: "",
        email: "",
        telephone: "",
        adresse: "",
        accessoires: [],
        revendeur: "",
        datefacture: new Date().toLocaleDateString(),
        numerofacture: "",
        marqueappareil: "",
        modeleappareil: "",
        etat_garantie: false,
        typeproduit: "",
        etat: "ouverte",
        datereception: new Date(),
        statutaffectation: "non affecté",
        numlotounumserie: "",
        livreur: "",
        representant: "",
        typerevendeur: "",
        affectepar: null,
        numeroticket: this.Num_Ticket,
      },
      newAccessoire: {
        libelle: "",
        reference: "",
        produit: "",
      },
      accessoires: [],
      numero: "",
      tickets: {},
      clickeditem: {},

      currentYear: new Date().getFullYear().toString().substr(-2),
      highestNumero: null,
      Bon_de_reception_ID: this.readFile(),
      Num_Ticket: this.readNumTicket(),
      test: true,
    };
  },

  created() {
    this.getclients();
    this.getLivreurs();
    this.getTypeProduits();
    this.getaccessoires();
    this.getRepresentants();
  },

  watch: {
    "ticket.typerevendeur"(val) {
      console.log(val);
      if (val == "Client final") {
        document.getElementById("revendeur").style.display = "none";
        document.getElementById("datefacture").style.display = "none";
        document.getElementById("numerofacture").style.display = "none";
        document.getElementById("revendeure").style.display = "none";
        document.getElementById("datefacturee").style.display = "none";
        document.getElementById("numerofacturee").style.display = "none";
        document.getElementById("clientfinal").style.display = "block";
        document.getElementById("clientfinale").style.display = "block";
      } else {
        document.getElementById("revendeur").style.display = "block";
        document.getElementById("datefacture").style.display = "block";
        document.getElementById("numerofacture").style.display = "block";
        document.getElementById("revendeure").style.display = "block";
        document.getElementById("datefacturee").style.display = "block";
        document.getElementById("numerofacturee").style.display = "block";
        document.getElementById("clientfinal").style.display = "none";
        document.getElementById("clientfinale").style.display = "none";
      }
    },
    client(val) {
      if (val) {
        const selectedClient = this.filteredClients.find(
          (client) => client.email === val
        );

        if (selectedClient) {
          // Définir les informations communes au ticket
          this.ticket = {
            IdClient: selectedClient.code,
            name: selectedClient.name,
            prenom: selectedClient.prenom,
            email: selectedClient.email,
            telephone: selectedClient.telephone,
            adresse: selectedClient.adresse,
            post: selectedClient.post,
          };

          // Gérer les informations spécifiques au type de client
          if (this.clientType === "societe" && selectedClient.societe) {
            this.societe = {
              raisonSociale: selectedClient.societe.raisonSociale || "",
              RNE: selectedClient.societe.RNE || "",
              MF: selectedClient.societe.MF || "",
              web: selectedClient.societe.web || "",
              fax: selectedClient.societe.fax || "",
              matricule_fiscale: selectedClient.societe.matricule_fiscale || "",
            };
          } else {
            this.societe = {}; // Réinitialiser si le client n'est pas une société
          }
        }
      }
    },

    "ticket.typeproduit"(val) {
      HTTP.get("accessoires/getaccessoiresbytype/" + val)
        .then((response) => {
          this.accessoirees = response.data;
        })

        .then((err) => console.log(err));
    },

    "ticket.ticket.typerevendeur"(val) {
      console.log(val);
      if (val == "Client final") {
        document.getElementById("revendeur").style.display = "none";
        document.getElementById("datefacture").style.display = "none";
        document.getElementById("numerofacture").style.display = "none";
        document.getElementById("revendeure").style.display = "none";
        document.getElementById("datefacturee").style.display = "none";
        document.getElementById("numerofacturee").style.display = "none";
        document.getElementById("clientfinal").style.display = "block";
        document.getElementById("clientfinale").style.display = "block";
      } else {
        document.getElementById("revendeur").style.display = "block";
        document.getElementById("datefacture").style.display = "block";
        document.getElementById("numerofacture").style.display = "block";
        document.getElementById("revendeure").style.display = "block";
        document.getElementById("datefacturee").style.display = "block";
        document.getElementById("numerofacturee").style.display = "block";
        document.getElementById("clientfinal").style.display = "none";
        document.getElementById("clientfinale").style.display = "none";
      }
    },
  },

  computed: {
    calculatenumero() {
      // Ensure `this.currentYear` is defined
      if (!this.currentYear) {
        console.error("currentYear is not defined");
        return;
      }

      let highestNumber = 0;

      // Find the highest ticket number in the existing tickets
      if (this.tickets && Array.isArray(this.tickets)) {
        for (const ticket of this.tickets) {
          const last = ticket.numeroticket;
          if (last && last.length === 13) {
            // Length of 13 for "TikYYYY00000"
            const lastNumber = last.slice(11); // Take the last 5 characters
            const parsedNumber = parseInt(lastNumber, 10);
            if (!isNaN(parsedNumber)) {
              highestNumber = Math.max(highestNumber, parsedNumber);
            }
          }
        }
      }

      // Find the highest ticket number in `arraynumbertickets`
      if (this.arraynumbertickets && Array.isArray(this.arraynumbertickets)) {
        for (const ticket of this.arraynumbertickets) {
          if (ticket && ticket.length === 13) {
            // Length of 13 for "TikYYYY00000"
            const lastNumber = ticket.slice(11); // Take the last 5 characters
            const parsedNumber = parseInt(lastNumber, 10);
            if (!isNaN(parsedNumber)) {
              highestNumber = Math.max(highestNumber, parsedNumber);
            }
          }
        }
      }

      // Create the new ticket number based on the highest found
      const newNumber = highestNumber + 1;
      const ticketNumber =
        "Tik" + this.currentYear + newNumber.toString().padStart(5, "0");

      console.log("Nouveau numéro de ticket:", ticketNumber);
      return ticketNumber; // Return the new ticket number
    },
  },

  methods: {
    getLivreurName(livreurId) {
      const livreur = this.livreurs.find((l) => l._id === livreurId); // Assuming _id is the key for livreurs
      return livreur ? livreur.nom : "Unknown Livreurs"; // Return the name or a fallback
    },

    getRepresentantName(representantId) {
      const representant = this.representants.find(
        (r) => r._id === representantId
      ); // Assuming _id is the key for representants
      return representant ? representant.nom : "Unknown Representant"; // Return the name or a fallback
    },
    openAddModalRepresentant() {
      this.addModalRepresentant = true;
    },
    closeAddModalRepresentant() {
      this.addModalRepresentant = false;
      this.resetNewRepresentant(); // Reset the form
    },
    resetNewRepresentant() {
      this.newRepresentant = {
        typeUser: "",
        nom: "",
        prenom: "",
        email: "",
        adresse: "",
        telephone: "",
      };
    },
    async addNewRepresentant() {
      if (this.$refs.form.validate()) {
        try {
          // Make the API request to add the new representative
          const response = await HTTP.post(
            "representants/addrepresentant",
            this.newRepresentant
          );

          // Show success notification
          this.$toast.success("Représentant ajouté avec succès!", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });

          // Refresh the list of representatives
          this.getRepresentants();

          // Clear the form and close the modal
          this.resetNewRepresentant();
          this.closeAddModalRepresentant();
        } catch (error) {
          // Show error notification
          this.$toast.error(
            "Une erreur est survenue lors de l'ajout du représentant.",
            {
              position: "top-right",
              timeout: 2000,
              closeOnClick: true,
            }
          );

          // Log the error for debugging
          console.error("Error adding representant:", error);
        }
      }
    },
    async getRepresentants() {
      try {
        console.log("Fetching representatives...");
        const response = await HTTP.get("representants/representants");
        console.log("Raw response:", response); // This will show the entire HTTP response, including status, headers, and data.

        // Log data specifically to verify if it contains the expected structure
        if (response.data) {
          this.representants = response.data;
          console.log("Representants data:", this.representants);
        } else {
          console.warn("No data returned in the response.");
        }
      } catch (error) {
        if (error.response) {
          // The request was made, but the server responded with a status code outside the 2xx range
          console.error("Error response from server:", error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received:", error.request);
        } else {
          // Something else caused the error
          console.error("Error:", error.message);
        }
      }
    },
    /////////////////////////////////////////////////////////////////////
    openAddModalAccessoir() {
      this.addModalaccessoir = true;
    },
    closeAddModalAccessoir() {
      this.addModalaccessoir = false;
      this.resetNewAccessoire();
    },

    async addNewAccessoire() {
      if (this.$refs.form.validate()) {
        try {
          // Make the API request to add the new accessory
          const response = await HTTP.post(
            "accessoires/addaccesoire",
            this.newAccessoire
          );

          // Show success notification
          this.$toast.success("Accessoire ajouté avec succès!", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });

          // Refresh the list of accessoires
          this.getaccessoires();

          // Clear the form and close the modal
          this.newAccessoire = { libelle: "", reference: "", produit: "" };
          this.closeAddModalAccessoir();
        } catch (error) {
          // Show error notification
          this.$toast.error(
            "Une erreur est survenue lors de l'ajout de l'accessoire.",
            {
              position: "top-right",
              timeout: 2000,
              closeOnClick: true,
            }
          );

          // Log the error for debugging
          console.error("Error adding accessoire:", error);
        }
      }
    },
    resetNewAccessoire() {
      this.newAccessoire = {
        libelle: "",
        reference: "",
        produit: "",
      };
    },
    async getaccessoires() {
      try {
        const response = await HTTP.get("accessoires/getaccessoires");
        this.accessoires = response.data; // Populate the list with the data received
      } catch (error) {
        console.error("Error fetching accessoires:", error);
      }
    },
    //////////////////////////
    openAddModal() {
      this.addModalproduit = true;
    },
    closeAddModal() {
      this.addModalproduit = false;
      this.resetNewTypeProduit();
    },

    resetNewTypeProduit() {
      this.newTypeProduit = {
        nom: "",
        description: "",
        categorie: "",
      };
    },
    async getTypeProduits() {
      try {
        const response = await HTTP.get("typeproduit/tous");
        this.typeproduits = response.data;
      } catch (error) {
        console.error("Error fetching type produits:", error);
      }
    },
    async addTypeProduit() {
      if (this.$refs.form.validate()) {
        try {
          const response = await HTTP.post(
            "typeproduit/ajouter",
            this.newTypeProduit
          );
          this.$toast.success("Type de produit ajouté avec succès !", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });
          this.getTypeProduits();
          this.closeAddModal();
        } catch (error) {
          this.$toast.error(
            "Une erreur est survenue lors de l'ajout du type de produit.",
            {
              position: "top-right",
              timeout: 2000,
              closeOnClick: true,
            }
          );
          console.error("Error adding type produit:", error);
        }
      }
    },
    ///////////////////////////////////////////////////////////
    printBarcode() {
      this.dialogcode = false;
      const printContainer = document.getElementById("print-container");
      printContainer.style.display = "block";

      this.$nextTick(() => {
        setTimeout(() => {
          printJS({
            printable: "print-container",
            type: "html",
            scanStyles: false,
            onPrintDialogClose: () => {
              // Réinitialiser l'état après l'impression
              this.ticketNumber = ""; // Réinitialiser le numéro du ticket
              printContainer.style.display = "none"; // Re-cacher le conteneur d'impression
            },
          });
        }, 500); // Ajustez le délai si nécessaire
      });
    },
    openAddLivreurModal() {
      this.addLivreurModal = true;
    },
    closeAddLivreurModal() {
      this.addLivreurModal = false;
    },
    async addLivreur() {
      if (this.$refs.form.validate()) {
        try {
          const response = await HTTP.post("livreurs/ajout", this.newLivreur);
          this.$toast.success("Livreur ajouté avec succès !", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });

          if (response.status !== 200) {
            this.$toast.error(
              "Une erreur est survenue lors de l'ajout du livreur.",
              {
                position: "top-right",
                timeout: 2000,
                closeOnClick: true,
              }
            );
            throw new Error(
              "Une erreur est survenue lors de l'ajout du livreur."
            );
          }

          this.getLivreurs();
          this.closeAddLivreurModal();
        } catch (error) {
          console.error(error);
          alert("Erreur lors de l'ajout du livreur.");
        }
      }
    },

    increment_highest() {
      this.highestNumero = (parseInt(this.highestNumero) + 1).toString();
    },
    async updateJsonFile() {
      try {
        // Your new data to write to the JSON file
        const newData = {
          idBon: (parseInt(this.Bon_de_reception_ID, 10) + 1).toString(),
        };

        // Call the API to update the JSON file
        const response = await HTTP.post("update-json", newData);

        console.log(response.data.message); // Log the success message
      } catch (error) {
        console.error("Error updating the JSON file:", error);
      }
    },
    async readFile() {
      try {
        const response = await HTTP.get("get-json");
        const data = response.data.data.idBon;
        this.Bon_de_reception_ID = "Rec" + data;
      } catch (error) {
        console.error("Error getting the JSON file:", error);
      }
    },

    async updateNumTicket() {
      try {
        const newData = {
          numTicket: (parseInt(this.Num_Ticket, 10) + 1).toString(),
        };

        const response = await HTTP.post("update-num-ticket", newData);

        console.log(response.data.message);
      } catch (error) {
        console.error("Error updating the JSON file:", error);
      }
    },
    async readNumTicket() {
      try {
        const response = await HTTP.get("get-num-ticket");
        const data = response.data.data.numTicket;
        this.Num_Ticket = "Tic" + data;
      } catch (error) {
        console.error("Error getting the JSON file:", error);
      }
    },
    emailExists(mail) {
      if (mail !== "") {
        HTTP.get("tickets/getemail/" + mail)
          .then((response) => {
            console.log(response.data);
            if (response.data == "adresse mail existe") {
              console.log(response.data);
              this.mailMessage = response.data;
              this.existingMail = false;
            } else {
              this.mailMessage = "";
              this.existingMail = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    delay() {
      this.disabled = true;

      // Re-enable after 5 seconds
      this.timeout = setTimeout(() => {
        this.disabled = false;
      }, 1000);

      this.saveallticket();
      // this.$router.push("/tickets");
    },
    deleteticket(id) {
      this.arrayticket.tickets.forEach((ticket) => {
        if (ticket.ticket.numeroticket == id) {
          this.arrayticket.tickets = this.arrayticket.tickets.filter(
            (ticket) => ticket.ticket.numeroticket !== id
          );
          this.$toast.success("Ticket supprimé!", {
            position: "top-right",
          });
        }
      });
    },

    // print numero ticket
    download() {
      const html = this.$refs.content.innerHTML;
      var myWindow = window.open("", "", "width=400,height=300");
      myWindow.document.write(html);

      myWindow.document.close();
      myWindow.focus();
      myWindow.print();
      myWindow.close();
    },
    getLivreurs() {
      HTTP.get("livreurs/tous")
        .then((response) => {
          this.livreurs = response.data;
        })
        .catch((err) => console.log(err));
    },
    addClientTicket() {
      if (this.$refs.form.validate()) {
        // Activer le chargement
        this.loading = true;

        const client = {
          name: this.ticket.name,
          prenom: this.ticket.prenom,
          email: this.ticket.email,
          telephone: this.ticket.telephone,
          adresse: this.ticket.adresse,
          typeUser: this.clientType,
          societe:
            this.clientType === "societe"
              ? {
                  raisonSociale: this.societe.raisonSociale,
                  RNE: this.societe.RNE,
                  MF: this.societe.MF,
                  matricule_fiscale: this.societe.matricule_fiscale,
                  web: this.societe.web,
                  fax: this.societe.fax,
                }
              : null,
        };

        HTTP.post("clients/addclient", client)
          .then(() => {
            this.$toast.success("Client ajouté avec succès!", {
              position: "top-right",
              timeout: 2000,
              closeOnClick: true,
            });

            // Mettre à jour les états de visibilité après l'ajout du client
            this.clientisHidden = true;
            this.ticketisHidden = false;

            // Récupérer les clients après l'ajout
            return HTTP.get("clients/getclients");
          })
          .then((response) => {
            const newClient = {
              name: this.ticket.name,
              prenom: this.ticket.prenom,
              adresse: this.ticket.adresse,
              telephone: this.ticket.telephone,
              email: this.ticket.email,
              matricule_fiscale: this.ticket.matricule_fiscale,
            };

            let clients = response.data;
            if (!clients.some((client) => client.email === this.ticket.email)) {
              clients.push(newClient);
            }

            this.revendeurs = clients;
            this.revendeursold = clients;

            console.log(clients);
          })
          .catch((error) => {
            console.error(error);
            this.$toast.error(
              "Une erreur est survenue lors de l'ajout du client !",
              {
                position: "top-right",
                timeout: 2000,
                closeOnClick: true,
              }
            );
          })
          .finally(() => {
            // Désactiver le chargement
            this.loading = false;
          });
      }
    },

    hideandshow() {
      this.readFile();

      this.clientisHidden = true;
      this.ticketisHidden = false;
      HTTP.get("clients/getclients")
        .then((response) => {
          const newClient = {
            name: this.ticket.name,
            prenom: this.ticket.prenom,
            adresse: this.ticket.adresse,
            telephone: this.ticket.telephone,
            email: this.ticket.email,
          };

          let clients = response.data;
          if (!clients.some((client) => client.email === this.ticket.email)) {
            clients.push(newClient);
          }

          this.revendeurs = clients;
          this.revendeursold = clients;

          console.log(clients);
          this.updateJsonFile().then(() => {
            this.readFile();
          });
        })
        .catch((err) => {
          console.error(err);
          Swal.fire({
            icon: "error",
            title: "Erreur",
            text: "Une erreur est survenue lors de la récupération des clients.",
          });
        });
    },

    AddTicketCodeabar(Num_Ticket) {
      this.addnewticket();
      this.openBarcodeDialog(Num_Ticket);
    },
    openBarcodeDialog(Num_Ticket) {
      this.Num_Ticket = Num_Ticket;
      this.dialogcode = true;
    },
    addnewticket() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
        let client = {
          name: this.ticket.name,
          prenom: this.ticket.prenom,
          adresse: this.ticket.adresse,
          telephone: this.ticket.telephone,
          email: this.ticket.email,
          matricule_fiscale: this.ticket.email,
        };

        let ticket = {
          numeroticket: this.Num_Ticket,
          typerevendeur: this.ticket.clientType,
          revendeur: this.ticket.name,
          datefacture: this.ticket.datefacture,
          numerofacture: this.ticket.numerofacture,
          numlotounumserie: this.ticket.numlotounumserie,
          typeproduit: this.ticket.typeproduit,
          marqueappareil: this.ticket.marqueappareil,
          modeleappareil: this.ticket.modeleappareil,
          livreur: this.ticket.livreur,
          representant: this.ticket.representant,
          etat_garantie: this.ticket.etat_garantie,
          affectepar: null,
          etat: "ouverte",
          statutaffectation: this.statutaffectation,
          pannesreclames: this.ticket.pannesreclames,
          accessoires: this.ticket.accessoires,

          datereception: new Date(),
        };
        this.arrayticket.client = client;
        this.arrayticket.tickets.push({ ticket });
        this.arraynumbertickets.push(ticket.numeroticket);
        this.updateNumTicket().then(() => {
          this.readNumTicket();
        });
        console.log("numticket", this.Num_Ticket);

        this.$toast.success("Ticket ajouté!", {
          position: "top-right",
          timeout: 2000,
          closeOnClick: true,
        });
        (this.ticket.numeroticket = ""),
          (this.ticket.typerevendeur = ""),
          (this.ticket.revendeur = ""),
          (this.ticket.datefacture = ""),
          (this.ticket.numerofacture = ""),
          (this.ticket.numlotounumserie = ""),
          (this.ticket.typeproduit = ""),
          (this.ticket.marqueappareil = ""),
          (this.ticket.modeleappareil = ""),
          (this.snackbar = false);

        document.getElementById("table").style.display = "block";
        this.resetTicketFields();
        this.resetValidation();
        this.$router.push("/addticket");
      }
    },
    async fetchLivreur(livreurId) {
      try {
        const response = await HTTP.get(`/livreurs/livreur/${livreurId}`);
        return response.data.nom; // Assuming 'nom' is the field that contains the name
      } catch (error) {
        console.error(`Error fetching livreur with ID ${livreurId}:`, error);
        return null; // Return null in case of an error
      }
    },
    async fetchDetailsForTickets() {
      const fetchPromises = this.tickets.map(async (ticket) => {
        const livreurPromise = this.fetchLivreur(ticket.ticket.livreur);
        const representantPromise = this.fetchRepresentant(
          ticket.ticket.representant
        );

        // Wait for both promises to resolve
        const [livreurNom, representantNom] = await Promise.all([
          livreurPromise,
          representantPromise,
        ]);

        // Assign names to the ticket object
        ticket.livreurNom = livreurNom;
        ticket.representantNom = representantNom;
      });

      // Wait for all promises to complete
      await Promise.all(fetchPromises);
    },
    async fetchRepresentant(representantId) {
      try {
        const response = await HTTP.get(
          `/representants/representants/${representantId}`
        );
        return response.data.nom; // Assuming 'nom' is the field that contains the name
      } catch (error) {
        console.error(
          `Error fetching representant with ID ${representantId}:`,
          error
        );
        return null; // Return null in case of an error
      }
    },
    resetTicketFields() {
      // Réinitialiser tous les champs sauf `numeroticket` et `name`
      this.ticket.typerevendeur = "";
      this.ticket.revendeur = "";
      this.ticket.datefacture = "";
      this.ticket.numerofacture = "";
      this.ticket.numlotounumserie = "";
      this.ticket.typeproduit = "";
      this.ticket.marqueappareil = "";
      this.ticket.modeleappareil = "";
      this.ticket.livreur = null;
      this.ticket.representant = "";
      this.ticket.pannesreclames = "";
      this.ticket.accessoires = [];
    },

    getrevendeurs() {
      HTTP.get("clients/getclients").then((response) => {
        this.revendeurs = response.data;
        this.revendeursold = response.data;
      });
    },
    handleCheckboxChange() {
      this.resetForm();
      this.resetValidation();
    },
    resetForm() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    filterClientsByUserType(typeUser) {
      if (!this.clients) return;

      if (typeUser === "particulier") {
        this.filteredClients = this.clients.filter(
          (client) => client.typeUser === "particulier"
        );
      } else if (typeUser === "societe") {
        this.filteredClients = this.clients.filter(
          (client) => client.typeUser === "societe"
        );
      } else {
        this.filteredClients = [];
      }
    },
    handleRadioChange() {
      this.resetAndFilterClients(this.clientType);
    },
    resetAndFilterClients(typeUser) {
      this.resetForm();
      this.resetValidation();
      this.filterClientsByUserType(typeUser);
    },

    getclients() {
      HTTP.get("clients/getclients")
        .then((response) => {
          this.clients = response.data;
          this.filterClientsByUserType(this.clientType);
        })
        .catch((err) => console.log(err));
    },

    saveallticket() {
      console.log(this.arrayticket);

      // Envoyer les données au serveur
      HTTP.post("tickets/addmultiticket", this.arrayticket)
        .then((response) => {
          // Récupérer l'ID du client_ticket
          const ticketClientId = response.data.client_ticket_id;

          // Redirect to viewticket after saving
          this.$router.push({
            name: "viewticket",
            params: { id: ticketClientId },
          });

          // Show success toast
          this.$toast.success("Ticket sauvegardé !", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });

          // Email sending code has been removed
        })
        .catch((error) => {
          console.error("Erreur lors de l'ajout des tickets:", error);
        });
    },
  },
};
</script>
<style src="./Table.scss" lang="scss" />
<style scoped>
.greenbutton {
  border: 2px solid #536dfe;
  border-radius: 0px 3px 3px 0px;
  margin-bottom: 25px;
  width: 12%;
  height: 56px;
  right: 61px;
  top: 0px;
  background-color: #536dfe;
}
.greenbutton:hover {
  background-color: #536dfe;
  color: white;
}
.product-type-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: row;
}

.product-type-label {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 45px;
}
.product-garantie-label {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
  margin-left: 8px;
}
.bordered-row {
  border: 2px solid #ccc;
  padding: 30px;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 100%;
}
.custom-card {
  min-height: 400px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
}
.ticket-container {
  width: 100%;
  background-color: #eeeeee;

  min-height: 160px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}

.ticket-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ticket-icon {
  margin-bottom: 8px;
}

.ticket-label {
  font-weight: bold;
}
.class-center {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
#barcode-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.barcode-container {
  margin-bottom: 1rem;
}

#print-container {
  display: none !important;
}

@media print {
  #print-container {
    display: none !important;
  }
}

.bluebutton {
  border: 2px solid #536dfe;
  border-radius: 0px 3px 3px 0px;
  margin-bottom: -32px;
  width: 13%;
  height: 56px;
  left: 46vh;
  top: -86px;
  background-color: #536dfe;
}
.accessoire-container {
  display: flex;
  align-items: center;
}

.accessoire-select {
  flex-grow: 1;
}

.accessoirebutton {
  border: 2px solid #536dfe;
  border-radius: 0 3px 3px 0;
  background-color: #536dfe;
  height: 56.5px;
  min-width: 52px;
  margin-left: -2px;
  top: -15px;
  left: -29px;
}
.representbutton {
  border: 2px solid #536dfe;
  border-radius: 0px 3px 3px 0px;
  margin-bottom: -32px;
  width: 13%;
  height: 56px;
  left: 47vh;
  top: -86px;
  background-color: #536dfe;
}
</style>
