<template>
  <div class="container-login">
    <div class="container-image"></div>
    <div class="container-form">
      <div class="logo">
        <img src="../../assets/Trustlink.png" alt="Logo" />
      </div>
      <span class="identifier">S'identifier</span>
      <v-form
        class="d-flex flex-column align-center"
        style="width: 100%; max-width: 400px; padding: 20px"
      >
        <v-col>
          <v-text-field
            v-model="email"
            :error-messages="v$.email.$errors.map((e) => e.$message)"
            label="E-mail"
            required
            @blur="v$.email.$touch"
            @input="v$.email.$touch"
            class="input-label"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            hint="At least 8 characters"
            label="Mot de passe"
            name="input-10-1"
            counter
            @click:append="toggleShowPassword"
            class="input-label"
          ></v-text-field>
        </v-col>

        <!-- Bouton de validation -->
        <div class="mb-3 form-check">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" />
          <label class="form-check-label" for="exampleCheck1"
            >Souviens de moi</label
          >
        </div>
        <div class="form-footer">
          <v-btn
            @click="login"
            :disabled="password.length === 0 || email.length === 0"
          >
            Connexion
          </v-btn>
        </div>
        <router-link to="/resetpassword" class="forgot-password-link"
          >Mot de passe oublié ?
        </router-link>
      </v-form>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/axios";
import { reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";

const initialState = {
  email: "",
};

const state = reactive({
  ...initialState,
});

const rules = {
  email: { required, email },
};

const v$ = useVuelidate(rules, state);

export default {
  name: "Login",
  setup() {
    function validate() {
      if (v$.value.$pending) {
        v$.value.$touch();
      }
      if (v$.value.$error) {
        // Handle validation errors
      } else {
        // Handle form submission
      }
    }

    return {
      state,
      v$,
      validate,
      password: "",
      rules,
    };
  },
  data() {
    return {
      errors: [],
      show1: false,

      email: "",
      emailRules: [
        (v) => !!v || "L'e-mail est obligatoire",
        (v) => /.+@.+/.test(v) || "L'e-mail doit être valide",
      ],
      password: "",
      passRules: [
        (v) => !!v || "Le mot de passe est obligatoire",
        (v) => v.length >= 8 || "Au moins 8 caractères",
      ],
      createFullName: "",
      createEmail: "",
      createPassword: "",
    };
  },
  methods: {
    toggleShowPassword() {
      this.show1 = !this.show1;
    },
    // Login method
    login() {
      let user = {
        email: this.email,
        password: this.password,
      };
      console.log(user);

      HTTP.post("auth/login", user)
        .then((res) => {
          if (res.status === 200) {
            var user = [];
            // console.log(res.data.user);
            const firstConnection = res.data.user.firstConnection;
            console.log(firstConnection);

            user.push(res.data.user);

            // Stockage du token et des données utilisateur dans le localStorage
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("userData", JSON.stringify(user));

            const expirationTime = new Date().getTime() + 60 * 60 * 1000;
            localStorage.setItem("tokenExpiration", expirationTime);
            // Redirection vers le tableau de bord
            this.$router.push(firstConnection ? "/profil" : "/dashboard");
          }
        })
        .catch((err) => {
          this.error = err.response.data.error;
          this.errors.push(this.error);
          console.log(this.error);
        });
    },
    // Register method
    register() {
      let user = {
        email: this.createEmail,
        name: this.createFullName,
        password: this.createPassword,
      };
      HTTP.post("auth/register", user)
        .then(() => {
          this.$toast.success("Welcome!", {
            position: "top-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.$router.push("/dashboard");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    logout() {
      // Supprimer le token et les données utilisateur du localStorage
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      localStorage.removeItem("tokenExpiration");

      // Rediriger l'utilisateur vers la page de login
      this.$router.push("/login");
    },
    checkTokenExpiration() {
      const expirationTime = localStorage.getItem("tokenExpiration");
      if (expirationTime && new Date().getTime() > expirationTime) {
        this.logout();
      }
    },
  },
};
</script>

<style scoped>
.input-label .v-label {
  font-size: 0.875rem;
}

.container-login {
  display: flex;
  height: 100vh;
  overflow: hidden;
  width: 100% !important;
}

.container-image {
  flex: 2.3;
  background: url("https://www.ltvplus.com/wp-content/uploads/2019/06/Good-Customer-Service-featured.png")
    no-repeat center center;
  background-size: cover;
}

.container-form {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f7ff;
  flex-direction: column;
  gap: 2.5rem;
  min-width: 350px;
}

.identifier {
  color: #536dfe !important;
  font-size: 1.8rem;
  border-bottom: 2px solid #536dfe;
  width: 60%;
  display: inline-block;
  text-align: center;
}

.form-check {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.form-check-input {
  margin: 0;
}

.form-check-label {
  font-size: 0.9rem;
}

.forgot-password-link {
  font-size: 0.875rem;
  color: #007bff;
  text-decoration: none;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

.form-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>
