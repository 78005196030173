<template>
  <v-container fluid>
    <div class="dashboard-page">
      <v-row no-gutters class="d-flex justify-space-between mt-10 mb-6">
        <h1 class="page-title">Pannes</h1>
        <v-menu offset-y>
          <!-- <template v-slot:activator="{ on, attrs }">
            <router-link to="/addpanne">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="secondary"
                class="text-capitalize button-shadow mr-1"
                >Ajouter Panne</v-btn
              >
            </router-link>
          </template> -->
        </v-menu>
        <!-- <v-dialog
                style="float:right"
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Add Panne
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Panne</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
                <v-col cols="12">
        <v-combobox
          v-model="categorie"
          :items="categories"
          label="Categorie"
       
          outlined
          dense
        ></v-combobox>
      </v-col>
         <v-col cols="12">
        <v-combobox
          v-model="article"
          :items="articles"
          item-text="name"
          label="Article"
         
          outlined
          dense
        ></v-combobox>
      </v-col>
         <v-col cols="12">
        <v-combobox
          v-model="name"
          :items="names"
          item-text="name"
          label="Description"
        
          outlined
          dense
        ></v-combobox>
      </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="addpanne()"
            
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
      </v-row>

      <v-row>
        <br />
        <v-col cols="12">
          <v-card class="support-requests mx-1 mb-1">
            <v-card-title class="pa-6 pb-0 white--text info">
              <p class="white--text">Liste des pannes</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="pl-2">
                    <tr>
                      <th class="text-left pa-6">Categorie</th>
                      <th class="text-left">Article</th>
                      <th class="text-left">Description</th>
                      <th class="text-left">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(panne, key) in pannes" :key="key">
                      <td>{{ panne.groupe }}</td>
                      <td>{{ panne.article }}</td>
                      <td>{{ panne.name }}</td>

                      <td>
                        <v-btn
                          @click="deletepanne(panne._id)"
                          class="ma-2"
                          color="red"
                          dark
                        >
                          Supprimer
                          <v-icon dark right> mdi-cancel </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { HTTP } from "@/axios";

export default {
  data() {
    return {
      pannes: [],
      dialog: false,

      categorie: "",
      name: "",
      article: "",
      categories: [
        "Gros électroménager",
        "Petit électroménager",
        "Entretien maison",
        "Bricolage",
        "Jardin",
      ],
      articles: [
        { name: "Lave linge", groupe: "Gros électroménager" },
        { name: "Lave-vaisselle", groupe: "Gros électroménager" },
        { name: "Cuisinière", groupe: "Gros électroménager" },
        { name: "Congélateur", groupe: "Gros électroménager" },
        { name: "Réfrigérateur", groupe: "Gros électroménager" },
        { name: "four", groupe: "Gros électroménager" },

        ////////
        { name: "Cafetière", groupe: "Petit électroménager" },
        { name: "Batteur électrique", groupe: "Petit électroménager" },
        { name: "Grille-pain", groupe: "Petit électroménager" },
        { name: "Robot cuiseur", groupe: "Petit électroménager" },
        { name: "Gaufrier", groupe: "Petit électroménager" },
        { name: "Cafetière", groupe: "Petit électroménager" },
        ////////
        { name: "Aspirateur avec sac", groupe: "Entretien maison" },
        { name: "Aspirateur sans sac", groupe: "Entretien maison" },
        { name: "Dracula", groupe: "Entretien maison" },
        { name: "Radiateur à inertie", groupe: "Entretien maison" },
        { name: "Radiateur", groupe: "Entretien maison" },
        ///////
        { name: "Perceuse-visseuse", groupe: "Bricolage" },
        { name: "Perceuse", groupe: "Bricolage" },
        { name: "Scie circulaire", groupe: "Bricolage" },
        { name: "Tournevis électrique", groupe: "Bricolage" },
        { name: "Meuleuse", groupe: "Bricolage" },
        ///////
        { name: "Nettoyeur à haute pression", groupe: "Jardin" },
        { name: "Tondeuse à gazon électrique", groupe: "Jardin" },
        { name: "motoculteur", groupe: "Jardin" },
        { name: "Tronçonneuse", groupe: "Jardin" },
        //////
      ],
      articlesold: [
        { name: "Lave linge", groupe: "Gros électroménager" },
        { name: "Lave-vaisselle", groupe: "Gros électroménager" },
        { name: "Cuisinière", groupe: "Gros électroménager" },
        { name: "Congélateur", groupe: "Gros électroménager" },
        { name: "Réfrigérateur", groupe: "Gros électroménager" },
        { name: "four", groupe: "Gros électroménager" },

        ////////
        { name: "Cafetière", groupe: "Petit électroménager" },
        { name: "Batteur électrique", groupe: "Petit électroménager" },
        { name: "Grille-pain", groupe: "Petit électroménager" },
        { name: "Robot cuiseur", groupe: "Petit électroménager" },
        { name: "Gaufrier", groupe: "Petit électroménager" },
        { name: "Cafetière", groupe: "Petit électroménager" },
        ////////
        { name: "Aspirateur avec sac", groupe: "Entretien maison" },
        { name: "Aspirateur sans sac", groupe: "Entretien maison" },
        { name: "Dracula", groupe: "Entretien maison" },
        { name: "Radiateur à inertie", groupe: "Entretien maison" },
        { name: "Radiateur", groupe: "Entretien maison" },
        ///////
        { name: "Perceuse-visseuse", groupe: "Bricolage" },
        { name: "Perceuse", groupe: "Bricolage" },
        { name: "Scie circulaire", groupe: "Bricolage" },
        { name: "Tournevis électrique", groupe: "Bricolage" },
        { name: "Meuleuse", groupe: "Bricolage" },
        ///////
        { name: "Nettoyeur à haute pression", groupe: "Jardin" },
        { name: "Tondeuse à gazon électrique", groupe: "Jardin" },
        { name: "motoculteur", groupe: "Jardin" },
        { name: "Tronçonneuse", groupe: "Jardin" },
        //////
      ],
      names: [
        { name: "Allumage Démarrage", groupe: "Lave linge" },
        { name: "Fuite d’eau", groupe: "Lave linge" },
        { name: "Bruit anormal", groupe: "Lave linge" },
        { name: "Problème d’essorage", groupe: "Lave linge" },
        { name: "Pas de vidange", groupe: "Lave linge" },

        ////////
        { name: "lave-vaisselle ne prend pas l’eau", groupe: "Lave-vaisselle" },
        { name: "lave-vaisselle fuit", groupe: "Lave-vaisselle" },
        { name: " lave-vaisselle ne sèche plus", groupe: "Lave-vaisselle" },
        { name: "lave-vaisselle ne vidange plus", groupe: "Lave-vaisselle" },
        {
          name: "lave-vaisselle laisse des traces blanches",
          groupe: "Lave-vaisselle",
        },

        ////////
        { name: "congélateur fuit", groupe: "Congélateur" },
        { name: "congélateur fait beaucoup de glace", groupe: "Congélateur" },
        { name: "congélateur ne fonctionne plus", groupe: "Congélateur" },
        { name: "congélateur fait du bruit ", groupe: "Congélateur" },
        { name: "congélateur givre", groupe: "Congélateur" },
        ///////
        {
          name: "ni les feux ni le four de la cuisinière ne s’allument",
          groupe: "Cuisinière",
        },
        {
          name: "le four de cuisinière ne fonctionne plus",
          groupe: "Cuisinière",
        },
        {
          name: "les flammes de cuisinière sont trop fortes",
          groupe: "Cuisinière",
        },
        {
          name: "les flammes de  cuisinière sont trop petites",
          groupe: "Cuisinière",
        },
        { name: "cuisinière noircit les casseroles", groupe: "Cuisinière" },
        ///////
        { name: "réfrigérateur givre", groupe: "Réfrigérateur" },
        { name: "réfrigérateur coule", groupe: "Réfrigérateur" },
        { name: "réfrigérateur fait du bruit", groupe: "Réfrigérateur" },
        { name: "réfrigérateur ne refroidit plus", groupe: "Réfrigérateur" },
        { name: "réfrigérateur fait de l’eau", groupe: "Réfrigérateur" },
        ///////
        { name: "four rouillee", groupe: "four" },
        { name: "four fume", groupe: "four" },
        { name: "four ne chauffe plus", groupe: "four" },
        { name: "four s’éteint tout seul", groupe: "four" },
        { name: "four fait du bruit", groupe: "four" },
      ],
      namesold: [
        { name: "Allumage Démarrage", groupe: "Lave linge" },
        { name: "Fuite d’eau", groupe: "Lave linge" },
        { name: "Bruit anormal", groupe: "Lave linge" },
        { name: "Problème d’essorage", groupe: "Lave linge" },
        { name: "Pas de vidange", groupe: "Lave linge" },

        ////////
        { name: "lave-vaisselle ne prend pas l’eau", groupe: "Lave-vaisselle" },
        { name: "lave-vaisselle fuit", groupe: "Lave-vaisselle" },
        { name: " lave-vaisselle ne sèche plus", groupe: "Lave-vaisselle" },
        { name: "lave-vaisselle ne vidange plus", groupe: "Lave-vaisselle" },
        {
          name: "lave-vaisselle laisse des traces blanches",
          groupe: "Lave-vaisselle",
        },

        ////////
        { name: "congélateur fuit", groupe: "Congélateur" },
        { name: "congélateur fait beaucoup de glace", groupe: "Congélateur" },
        { name: "congélateur ne fonctionne plus", groupe: "Congélateur" },
        { name: "congélateur fait du bruit ", groupe: "Congélateur" },
        { name: "congélateur givre", groupe: "Congélateur" },
        ///////
        {
          name: "ni les feux ni le four de la cuisinière ne s’allument",
          groupe: "Cuisinière",
        },
        {
          name: "le four de cuisinière ne fonctionne plus",
          groupe: "Cuisinière",
        },
        {
          name: "les flammes de cuisinière sont trop fortes",
          groupe: "Cuisinière",
        },
        {
          name: "les flammes de  cuisinière sont trop petites",
          groupe: "Cuisinière",
        },
        { name: "cuisinière noircit les casseroles", groupe: "Cuisinière" },
        ///////
        { name: "réfrigérateur givre", groupe: "Réfrigérateur" },
        { name: "réfrigérateur coule", groupe: "Réfrigérateur" },
        { name: "réfrigérateur fait du bruit", groupe: "Réfrigérateur" },
        { name: "réfrigérateur ne refroidit plus", groupe: "Réfrigérateur" },
        { name: "réfrigérateur fait de l’eau", groupe: "Réfrigérateur" },
        ///////
        { name: "four rouillee", groupe: "four" },
        { name: "four fume", groupe: "four" },
        { name: "four ne chauffe plus", groupe: "four" },
        { name: "four s’éteint tout seul", groupe: "four" },
        { name: "four fait du bruit", groupe: "four" },
      ],
    };
  },

  computed: {
    searchFunction() {
      return this.pannes.filter((item) => {
        return (
          item.numero.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },
  watch: {
    categorie: function (val) {
      if (val) {
        this.articles = this.articlesold;
        let filter = this.articles.filter((d) => d.groupe == val);

        this.articles = filter;
      }
    },
    article: function (val) {
      if (val) {
        this.names = this.namesold;
        let filter = this.names.filter((d) => d.groupe == val.name);

        this.names = filter;
      }
    },
  },
  created() {
    this.getpannes();
  },

  methods: {
    getpannes() {
      HTTP.get("pannes/getpannes")
        .then((response) => {
          this.pannes = response.data;
        })

        .then((err) => console.log(err));
    },

    deletepanne(id) {
      console.log(id);

      HTTP.delete("pannes/deletepanne/" + id).then((response) => {
        //this.$htmlToPaper("printNow");
        console.log(response);

        this.$toast.success("Panne supprimé!", {
          position: "top-right",
          timeout: 1000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,

          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });

        this.getpannes();
      });
    },
    addpanne() {
      let panne = {
        name: this.name.name,
        categorie: this.categorie,
        article: this.article.name,
      };
      HTTP.post("pannes/addpanne", panne).then(
        (res) => {
          if (res.status === 200) {
            this.$toast.success("Panne ajouté!", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.dialog = false;

            this.getpannes();
            (this.categorie = ""), (this.name = ""), (this.article = "");
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },
  },
};
</script>

<style src="./Dashboard.scss" lang="scss" />
