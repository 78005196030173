<template>
  <v-row>
    <v-col cols="12">
      <v-card class="support-requests mx-1 mb-1 pa-5">
        <v-card-text>
          <v-col cols="4" md="4">
            <v-text-field
              v-model="search"
              color="#2382bf"
              type="text"
              hide-no-data
              placeholder="Recherche client"
              prepend-icon="mdi-database-search"
              dense
            ></v-text-field
          ></v-col>
        </v-card-text>
        <v-card-text class="pa-3">
          <v-data-table
            :headers="headers"
            :items="displayedclients"
            :items-per-page="itemsPerPage"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat class="pa-2 grey lighten-4 toolbar-border">
                <v-toolbar-title class="info--text"
                  >Gestion des clients</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-radio-group
                  @change="filterClientsByUserType($event)"
                  v-model="clientType"
                  row
                  class="custom-radio-group"
                >
                  <v-radio label="Tout" value="tout"></v-radio>
                  <v-radio label="Particulier" value="particulier"></v-radio>
                  <v-radio label="Société" value="societe"></v-radio>
                </v-radio-group>
                <v-spacer></v-spacer>
                <router-link to="/addclient">
                  <v-btn class="mb-2" color="primary" dark v-bind="props">
                    Nouveau Client
                  </v-btn>
                </router-link>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5">
                      Êtes-vous sûr de vouloir supprimer ce client?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue-darken-1"
                        variant="text"
                        @click="closeConfirmDelete"
                      >
                        Annuler
                      </v-btn>
                      <v-btn
                        color="blue-darken-1"
                        variant="text"
                        @click="deleteItemConfirm"
                      >
                        Confirmer
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon class="me-2" @click="editItem(item)"> mdi-pencil </v-icon>
              <v-icon @click="confirmDeleteClient(item)"> mdi-delete </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize">Réinitialiser</v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { HTTP } from "@/axios";
import Swal from "sweetalert2";
import Toast from "sweetalert2";

export default {
  data() {
    return {
      selectedClient: {},
      drawer: null,
      editedIndex: -1,
      editedItem: null,
      itemsPerPage: 5,
      currentPage: 1,
      clients: [],
      filteredClients: [],
      dialog: false,
      dialogDelete: false,
      search: "",
      clientType: "tout",
      headers: [
        { text: "Code", value: "code" },
        { text: "Nom & Prénom", value: "name" },
        { text: "Adresse", value: "adresse" },
        { text: "Téléphone", value: "telephone" },
        { text: "Email", value: "email" },
        { text: "Type client", value: "typeUser" },
        { text: "Matricule", value: "matricule_fiscale" },
        { text: "Actions", value: "actions" },
      ],
      editedIndex: -1,
      editedItem: {
        name: "",
        prenom: "",
        email: "",
        code: "",
        telephone: "",
        adresse: "",
        typeClient: "",
        matricule_fiscale: "",
      },
      defaultItem: {
        name: "",
        prenom: "",
        email: "",
        code: "",
        telephone: "",
        adresse: "",
        typeClient: "",
        matricule_fiscale: "",
      },
    };
  },

  computed: {
    displayedclients() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredClients
        .filter((client) => {
          const searchLower = this.search.toLowerCase();
          return (
            client.name.toLowerCase().includes(searchLower) ||
            client.prenom.toLowerCase().includes(searchLower) ||
            client.email.toLowerCase().includes(searchLower) ||
            client.adresse.toLowerCase().includes(searchLower) ||
            client.telephone.includes(searchLower)
          );
        })
        .slice(startIndex, endIndex);
    },
  },

  created() {
    this.getclients();
  },

  methods: {
    filterClientsByUserType(typeUser) {
      if (!this.clients) return;
      if (typeUser === "particulier") {
        this.filteredClients = this.clients.filter(
          (client) => client.typeUser === "particulier"
        );
      } else if (typeUser === "societe") {
        this.filteredClients = this.clients.filter(
          (client) => client.typeUser === "societe"
        );
      } else if (typeUser === "tout") {
        this.filteredClients = this.clients;
      } else {
        this.filteredClients = this.clients;
      }
    },
    getclients() {
      HTTP.get("clients/getclients")
        .then((response) => {
          this.clients = response.data;
          this.filterClientsByUserType(this.clientType || "tout");
        })
        .catch((err) => console.log(err));
    },
    editItem(item) {
      this.editedIndex = this.clients.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    save() {
      if (this.editedIndex > -1) {
        HTTP.put("clients/update", this.editedItem)
          .then(() => {
            Object.assign(this.clients[this.editedIndex], this.editedItem);
            this.close();
            this.filterClientsByUserType(this.clientType || "tout");
            Toast.fire({
              icon: "success",
              title: "Client modifié avec succès",
            });
          })
          .catch((err) => console.log(err));
      } else {
        HTTP.post("clients/add", this.editedItem)
          .then(() => {
            this.clients.push(this.editedItem);
            this.close();
            this.filterClientsByUserType(this.clientType || "tout");
            Toast.fire({
              icon: "success",
              title: "Client ajouté avec succès",
            });
          })
          .catch((err) => console.log(err));
      }
    },

    confirmDeleteClient(client) {
      this.selectedClient = client;
      this.dialogDelete = true;
    },
    closeConfirmDelete() {
      this.dialogDelete = false;
      this.selectedClient = {}; // Réinitialiser à un objet vide
    },
    async deleteItemConfirm() {
      if (this.selectedClient) {
        try {
          await HTTP.delete(`clients/deleteclient/${this.selectedClient._id}`);
          console.log("supprimer");

          this.$toast.success("Client supprimé avec succès !", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
          });

          this.getclients();
          this.closeConfirmDelete();
        } catch (error) {
          this.$toast.error(
            "Une erreur est survenue lors de la suppression du livreur.",
            {
              position: "top-right",
              timeout: 2000,
              closeOnClick: true,
            }
          );
          console.error("Erreur lors de la suppression du livreur:", error);
        }
      }
    },

    close() {
      this.dialog = false;
      this.editedItem = { ...this.defaultItem };
      this.editedIndex = -1;
    },
  },
};
</script>

<style scoped></style>
